/*=======================================================================
1. Default Styles
=========================================================================*/
/*-------------------------
    Variables
-------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&display=swap");

:root body.active-dark-mode {
  --color-body: #c1c6c9;
  --color-black: #ffffff;
  --color-black-rgb: 255, 255, 255;
  --color-white: #000000;
  --color-white-rgb: 0, 0, 0;
  --color-light-1: #2e2e2e;
  --color-light-2: #4e4e4e;
  --color-light-3: #434343;
  --color-dark-1: #ffffff;
  --color-bg-light-1: #212121;
  --color-bg-light-2: #1c1c1c;
  --color-bg-light-3: #2e2e2e;
  --color-heading-1: #4e4e4e;
  --color-heading-2: #4e4e4e;
  --color-menu: #dddddd;
  --color-sub-menu: #dddddd;
  --color-meta-dark-1: #929292;
  --color-category-dark-1: #a4a4a4;
  --color-border-dark-1: #5c5c5c;
  --color-border-dark-2: #424242;
  --color-border-light-1: #dde0e4;
}

:root {
  --bootstrap-icons: bootstrap-icons !important;
  --color-body: #373b3e;
  --color-body-fixed: #494e51;
  --color-black: #000000;
  --color-black-fixed: #000000;
  --color-black-rgb: 0, 0, 0;
  --color-black-rgb-fixed: 0, 0, 0;
  --color-white: #ffffff;
  --color-white-fixed: #ffffff;
  --color-white-rgb: 255, 255, 255;
  --color-white-rgb-fixed: 255, 255, 255;
  --color-light-1: #ffffff;
  --color-light-1-fixed: #ffffff;
  --color-light-2: #f5f5f5;
  --color-light-2-fixed: #f5f5f5;
  --color-light-3: #d6d6d6;
  --color-light-3-fixed: #d6d6d6;
  --color-dark-1: #2e2e2e;
  --color-dark-1-fixed: #2e2e2e;
  --color-bg-light-1: #ffffff;
  --color-bg-light-2: #f8f8f5;
  --color-bg-light-3: #ffffff;
  --color-heading-1: #8ff6b8;
  --color-heading-2: #fde8e8;
  --color-primary: #f75959;
  --color-primary-rgb: 247, 89, 89;
  --color-menu: #4e4e4e;
  --color-menu-fixed: #4e4e4e;
  --color-sub-menu: #4e4e4e;
  --color-sub-menu-fixed: #4e4e4e;
  --color-meta-dark-1: #6b7074;
  --color-meta-dark-1-fixed: #6b7074;
  --color-meta-light-1-fixed: #d3d7da;
  --color-category-dark-1: #606468;
  --color-category-dark-1-fixed: #606468;
  --color-category-light-1-fixed: #dbe0e3;
  --color-border-dark-1: #303030;
  --color-border-dark-1-fixed: #303030;
  --color-border-dark-2: #dde0e4;
  --color-border-dark-2-fixed: #dde0e4;
  --color-border-light-1: #424242;
  --color-border-light-1-fixed: #424242;
  --color-scandal: #c5fbe1;
  --color-mimosa: #f0fdcd;
  --color-selago: #f1d1fc;
  --color-selago-smooth: #f9eafe;
  --color-old-lace: #fdeddd;
  --color-tidal: #e6fda8;
  --color-perano: #b4aaf3;
  --color-aquamarine: #8ff6b8;
  --color-polar: #d8f6f4;
  --color-atomic-tangerine: #fba574;
  --color-screamin-green: #7af189;
  --color-btn-bg: #a4f4f9;
  --color-medium-purple: #8c52d6;
  --color-ecru-white: #f9f8f0;
  --color-woodsmoke: #121418;
  --color-tundora: #4e4e4e;
  --color-shamrock: #4ade80;
  --reddit: #f74300;
  --snapchat: #f7f400;
  --threads: #000000;
  --whatsapp: #32d851;
  --facebook: #337fff;
  --instagram: #d62976;
  --twitter: #000000;
  --pinterest: #ff0000;
  --linkedin: #0072b1;
  --vimeo: #1ab7ea;
  --tiktok: #000000;
  --youtube: #ff0000;
  --dribbble: #444444;
  --mail-fast: #776cf2;
  --font-primary: "Roboto", sans-serif;
  --font-secondary: "Poppins", sans-serif;
  --font-icomoon: "icomoon" !important;
  --transition: all 0.3s ease-in-out;
  --transition-menu: all 0.4s cubic-bezier(0.3, 0.02, 0, 0.6);
  --transition-slider: 1s cubic-bezier(0.45, 0.05, 0.15, 0.93) 0.15s;
  --transition-figure: all 0.5s cubic-bezier(0.23, 0.03, 0, 1.08);
  --radius-default: 8px;
  --font-size-menu: 16px;
  --font-size-sub-menu: 15px;
  --font-weight-menu: 500;
  --font-weight-sub-menu: 500;
  --size-b1: 24px;
  --size-b2: 20px;
  --size-b3: 18px;
  --size-b4: 17px;
  --size-b5: 16px;
  --size-b6: 15px;
  --size-b7: 14px;
  --h1-large: 64px;
  --h1: 40px;
  --h2-large: 40px;
  --h2: 36px;
  --h2-medium: 24px;
  --h2-small: 20px;
  --h3-extra-large: 36px;
  --h3-large: 28px;
  --h3-regular: 24px;
  --h3: 22px;
  --h3-medium: 20px;
  --h3-small: 18px;
  --h3-extra-small: 16px;
  --h4-large: 24px;
  --h4: 20px;
  --h4-small: 18px;
}

@media (max-width: 767px) {
  :root {
    --size-b1: 22px;
  }
}

@media (max-width: 767px) {
  :root {
    --size-b2: 18px;
  }
}

@media (max-width: 767px) {
  :root {
    --size-b3: 17px;
  }
}

@media (max-width: 767px) {
  :root {
    --size-b4: 17px;
  }
}

@media (max-width: 991px) {
  :root {
    --h1-large: 56px;
  }
}

@media (max-width: 767px) {
  :root {
    --h1-large: 40px;
  }
}

@media (max-width: 575px) {
  :root {
    --h1-large: 36px;
  }
}

@media (max-width: 991px) {
  :root {
    --h1: 36px;
  }
}

@media (max-width: 767px) {
  :root {
    --h1: 32px;
  }
}

@media (max-width: 575px) {
  :root {
    --h1: 28px;
  }
}

@media (max-width: 991px) {
  :root {
    --h2-large: 36px;
  }
}

@media (max-width: 767px) {
  :root {
    --h2-large: 32px;
  }
}

@media (max-width: 575px) {
  :root {
    --h2-large: 28px;
  }
}

@media (max-width: 991px) {
  :root {
    --h2: 32px;
  }
}

@media (max-width: 767px) {
  :root {
    --h2: 28px;
  }
}

@media (max-width: 575px) {
  :root {
    --h2: 26px;
  }
}

@media (max-width: 991px) {
  :root {
    --h2-medium: 22px;
  }
}

@media (max-width: 767px) {
  :root {
    --h2-medium: 20px;
  }
}

@media (max-width: 575px) {
  :root {
    --h2-medium: 18px;
  }
}

@media (max-width: 767px) {
  :root {
    --h2-small: 18px;
  }
}

@media (max-width: 575px) {
  :root {
    --h2-small: 17px;
  }
}

@media (max-width: 991px) {
  :root {
    --h3-extra-large: 32px;
  }
}

@media (max-width: 767px) {
  :root {
    --h3-extra-large: 28px;
  }
}

@media (max-width: 575px) {
  :root {
    --h3-extra-large: 26px;
  }
}

@media (max-width: 991px) {
  :root {
    --h3-large: 26px;
  }
}

@media (max-width: 767px) {
  :root {
    --h3-large: 22px;
  }
}

@media (max-width: 575px) {
  :root {
    --h3-large: 20px;
  }
}

@media (max-width: 767px) {
  :root {
    --h3-regular: 22px;
  }
}

@media (max-width: 575px) {
  :root {
    --h3-regular: 20px;
  }
}

@media (max-width: 767px) {
  :root {
    --h3: 20px;
  }
}

@media (max-width: 575px) {
  :root {
    --h3: 18px;
  }
}

@media (max-width: 767px) {
  :root {
    --h3-medium: 18px;
  }
}

@media (max-width: 575px) {
  :root {
    --h3-medium: 17px;
  }
}

@media (max-width: 767px) {
  :root {
    --h3-small: 17px;
  }
}

@media (max-width: 575px) {
  :root {
    --h3-small: 16px;
  }
}

@media (max-width: 767px) {
  :root {
    --h3-extra-small: 15px;
  }
}

@media (max-width: 575px) {
  :root {
    --h3-extra-small: 14px;
  }
}

@media (max-width: 767px) {
  :root {
    --h4-large: 22px;
  }
}

@media (max-width: 575px) {
  :root {
    --h4-large: 20px;
  }
}

@media (max-width: 767px) {
  :root {
    --h4: 18px;
  }
}

@media (max-width: 575px) {
  :root {
    --h4: 16px;
  }
}

@media (max-width: 767px) {
  :root {
    --h4-small: 16px;
  }
}

@media (max-width: 575px) {
  :root {
    --h4-small: 15px;
  }
}

:root .g-1,
:root .gy-1 {
  --bs-gutter-y: 10px;
  --bs-gutter-x: 10px;
}

:root .g-2,
:root .gy-2 {
  --bs-gutter-y: 20px;
  --bs-gutter-x: 20px;
}

:root .g-3,
:root .gy-3 {
  --bs-gutter-y: 30px;
  --bs-gutter-x: 30px;
}

:root .g-4,
:root .gy-4 {
  --bs-gutter-y: 40px;
  --bs-gutter-x: 40px;
}

:root .g-5,
:root .gy-5 {
  --bs-gutter-y: 50px;
  --bs-gutter-x: 50px;
}

/*-------------------------
    Typography
-------------------------*/
body {
  font-family: var(--font-primary) !important;
  font-size: var(--size-b5);
  line-height: 1.55;
  color: var(--color-body);
  font-weight: 400;
  height: 100%;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

p {
  font-size: var(--size-b5);
  line-height: 1.55;
  margin: 0 0 20px 0;
  color: var(--color-body);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
table,
hr {
  margin: 0 0 20px 0;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: var(--font-secondary);
  font-weight: 600;
  color: var(--color-dark-1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

h1.h1-large {
  font-size: var(--h1-large);
  line-height: 1.22;
}

h1,
.h1 {
  font-size: var(--h1);
  line-height: 1.35;
}

h2.h2-large {
  font-size: var(--h2-large);
  line-height: 1.35;
}

h2,
.h2 {
  font-size: var(--h2);
  line-height: 1.28;
}

h2.h2-medium {
  font-size: var(--h2-medium);
  line-height: 1.42;
}

h2.h2-small {
  font-size: var(--h2-small);
  line-height: 1.5;
}

h3.h3-extra-large {
  font-size: var(--h3-extra-large);
  line-height: 1.28;
}

h3.h3-large {
  font-size: var(--h3-large);
  line-height: 1.36;
}

h3.h3-regular {
  font-size: var(--h3-regular);
  line-height: 1.42;
}

h3,
.h3 {
  font-size: var(--h3);
  line-height: 1.45;
}

h3.h3-medium {
  font-size: var(--h3-medium);
  line-height: 1.46;
}

h3.h3-small {
  font-size: var(--h3-small);
  line-height: 1.56;
}

h3.h3-extra-small {
  font-size: var(--h3-extra-small);
  line-height: 1.63;
}

h4.h4-large {
  font-size: var(--h4-large);
  line-height: 1.42;
}

h4,
.h4 {
  font-size: var(--h4);
  line-height: 1.5;
}

h4.h4-small {
  font-size: var(--h4-small);
  line-height: 1.56;
}

h5,
.h5 {
  font-size: var(--h5);
  line-height: 1.56;
}

h6,
.h6 {
  font-size: var(--h6);
  line-height: 1.62;
}

/*-------------------------
    Global
-------------------------*/
a {
  text-decoration: none;
}

img {
  max-width: 100%;
}

@media (min-width: 1300px) {
  .container {
    max-width: 1270px;
  }
}

.main-wrapper {
  overflow: hidden;
  background-color: var(--color-light-1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

button:focus-visible {
  outline: none;
}

button:focus:not(:focus-visible) {
  outline: 0 !important;
}

.image-mask {
  position: relative;
}

.image-mask:before {
  content: "";
  background: -webkit-linear-gradient(bottom,
      #020112 2.04%,
      rgba(52, 51, 59, 0.55) 41.28%,
      rgba(217, 217, 217, 0) 98.25%);
  background: linear-gradient(360deg,
      #020112 2.04%,
      rgba(52, 51, 59, 0.55) 41.28%,
      rgba(217, 217, 217, 0) 98.25%);
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  height: 50%;
  background-repeat: no-repeat;
  width: 100%;
  background-position: center bottom;
  background-size: cover;
}

.image-mask.radius-default:before {
  border-radius: 8px;
}

body .light-dark-switcher {
  position: relative;
}

body .light-dark-switcher .switcher-box {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

body .light-dark-switcher .light-mode {
  position: relative;
}

body .light-dark-switcher .dark-mode {
  position: absolute;
}

body.active-light-mode .light-dark-switcher .light-mode {
  opacity: 1;
  visibility: visible;
}

body.active-light-mode .light-dark-switcher .dark-mode {
  opacity: 0;
  visibility: hidden;
}

body.active-dark-mode .light-dark-switcher .light-mode {
  opacity: 0;
  visibility: hidden;
}

body.active-dark-mode .light-dark-switcher .dark-mode {
  opacity: 1;
  visibility: visible;
}

body .logo-holder .light-mode {
  opacity: 1;
  visibility: visible;
}

body .logo-holder .dark-mode {
  opacity: 0;
  visibility: hidden;
}

body.active-dark-mode .logo-holder .light-mode {
  opacity: 0;
  visibility: hidden;
}

/* Sidebar Css Start */
.custom-sidebar {
  position: fixed;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100%;
  background-color: var(--color-polar);
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  transition: left 0.3s ease-in-out;
  overflow-y: auto;
}

.custom-sidebar.open {
  left: 0;
}

.custom-sidebar-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
}

.close-sidebar-btn {
  align-self: flex-end;
  background: none;
  border: none;
  font-size: 18px;
  color: #333;
  cursor: pointer;
  position: absolute;
  z-index: 99;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 15px 0px;
}

.sidebar-menu li {
  position: relative;
}

.sidebar-menu li a,
.categories-dropdown-btn {
  text-decoration: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: var(--color-black);
  display: flex;
  align-items: center;
  padding: 8px 8px;
  border: none;
  background-color: transparent;
  width: 100%;
  position: relative;
}

.categories-dropdown-btn::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 8px;
  height: 8px;
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
}

.menu-icon {
  margin-right: 10px;
  font-size: 18px;
  width: 24px;
  height: 24px;
  object-fit: scale-down;
  display: flex;
  align-items: center;
  justify-content: center;
}

.categories-dropdown {
  list-style: none;
  margin: 8px 0px 0px;
  background-color: rgb(255 255 255 / 50%);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 2px 0px;
  padding: 12px 12px;
}

.categories-dropdown li {
  position: relative;
}

.categories-dropdown li a {
  font-size: 14px;
  color: var(--color-body);
  text-decoration: none;
  line-height: 18px;
  margin: 0;
  padding: 8px 8px;
  transition: all 0.2s ease-in-out;
  border-radius: 2px;
}

.categories-dropdown li a:hover {
  text-decoration: none;
  background-color: #fff;
}

.sidebar-toggle-btn {
  position: absolute;
  /* top: 15px; */
  left: 15px;
  background: none;
  border: none;
  cursor: pointer;
  /* z-index: 1100; */
}

.sidebar-toggle-btn img {
  width: 25px;
  height: 25px;
}

#sticky-placeholder {
  height: 0;
}

@media (max-width: 768px) {
  .custom-sidebar {
    width: 250px;
  }

  .custom-sidebar-content {
    padding: 15px;
  }

  .close-sidebar-btn {
    font-size: 18px;
  }

  .sidebar-menu li a {
    font-size: 1rem;
  }
}

/* Sidebar Ends Start */

body.active-dark-mode .logo-holder .dark-mode {
  opacity: 1;
  visibility: visible;
}

body .logo-holder {
  position: relative;
}

body .logo-holder .single-logo {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  left: 0;
  top: 0;
  right: 0;
}

body .logo-holder .light-mode {
  position: relative;
}

body .logo-holder .dark-mode {
  position: absolute;
}

.entry-title a {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.section-heading {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 38px;
}

@media (max-width: 767px) {
  .section-heading {
    margin-bottom: 20px;
  }

  .section-heading .title {
    margin: 0 auto;
  }
}

.section-heading.color-dark-1-fixed h1,
.section-heading.color-dark-1-fixed h2,
.section-heading.color-dark-1-fixed h3,
.section-heading.color-dark-1-fixed h4,
.section-heading.color-dark-1-fixed h5,
.section-heading.color-dark-1-fixed h6 {
  color: var(--color-dark-1-fixed);
}

.section-heading .title {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-bottom: 0;
}

.section-heading .title:before {
  content: "";
  width: 100%;
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.section-heading .link-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 7px;
  -moz-column-gap: 7px;
  column-gap: 7px;
  color: var(--color-dark-1);
  font-size: 14px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.humburger-icon {
  width: 2rem;
  cursor: pointer;
  position: absolute;
  left: 2%;
  top: 55%;
}

.section-heading .link-wrap .icon-holder {
  height: 21px;
  width: 21px;
  border-radius: 50%;
  background-color: var(--color-light-1);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--color-dark-1);
  font-size: 16px;
  border: 1px solid;
  border-color: var(--color-border-dark-1);
  box-shadow: 2px 0 0 0 var(--color-border-dark-1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.section-heading .link-wrap:hover {
  color: var(--color-primary);
}

.section-heading .link-wrap:hover .icon-holder {
  -webkit-transform: translateX(2px);
  -ms-transform: translateX(2px);
  transform: translateX(2px);
  box-shadow: -2px 0 0 0 var(--color-border-dark-1);
}

.section-heading.heading-style-1 .title:before {
  background-color: var(--color-heading-1);
  height: 20px;
}

.section-heading.heading-style-2 {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .section-heading.heading-style-2 {
    margin-bottom: 15px;
  }
}

.section-heading.heading-style-2 .title:before {
  background-color: var(--color-heading-1);
  height: 12px;
}

.section-heading.heading-style-3 {
  text-align: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .section-heading.heading-style-3 {
    margin-bottom: 30px;
  }
}

.section-heading.heading-style-3 .title {
  color: var(--color-light-1-fixed);
}

.section-heading.heading-style-3 .title:before {
  background-color: var(--color-medium-purple);
  height: 20px;
}

.section-heading.heading-style-4 .title {
  color: var(--color-light-1-fixed);
}

.section-heading.heading-style-4 .title:before {
  background-color: var(--color-tundora);
  height: 20px;
}

.section-heading.heading-style-5 .title:before {
  background-color: var(--color-heading-2);
  height: 20px;
}

.section-heading.heading-style-6 {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .section-heading.heading-style-6 {
    margin-bottom: 15px;
  }
}

/* home page css --start */
.card-lalest-storties {
  background-color: red !important;
  height: 60rem;
}

/* home page css --end */
.section-heading.heading-style-6 .title:before {
  background-color: var(--color-heading-2);
  height: 12px;
}

.section-heading.heading-style-7 {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .section-heading.heading-style-7 {
    margin-bottom: 15px;
  }
}

.section-heading.heading-style-7 .title {
  cursor: inherit;
}

.section-heading.heading-style-7 .title:before {
  display: none;
}

.section-heading.heading-style-8 {
  margin-bottom: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (max-width: 767px) {
  .section-heading.heading-style-8 {
    margin-bottom: 15px;
  }
}

.section-heading.heading-style-8 .title:before {
  display: none;
}

.section-heading.heading-style-9 {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-heading.heading-style-9 .title:before {
  display: none;
}

.section-heading.heading-style-9 .description {
  margin-bottom: 0;
}

.section-heading.heading-style-10 {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-heading.heading-style-10 .title {
  margin-bottom: 15px;
}

.section-heading.heading-style-10 .title:before {
  display: none;
}

.section-heading.heading-style-10 .description {
  text-align: center;
  margin-bottom: 0;
  max-width: 480px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.entry-title.color-dark-1 {
  color: var(--color-dark-1);
}

.entry-title.color-dark-1 a {
  color: var(--color-dark-1);
}

.entry-title.color-dark-1-fixed {
  color: var(--color-dark-1-fixed);
}

.entry-title.color-dark-1-fixed a {
  color: var(--color-dark-1-fixed);
}

.entry-description.color-dark-1 {
  color: var(--color-body);
}

.entry-description.color-dark-1-fixed {
  color: var(--color-body-fixed);
}

.entry-description.color-light-1-fixed {
  color: var(--color-light-1-fixed);
}

.entry-title.color-light-1 {
  color: var(--color-light-1);
}

.entry-title.color-light-1 a {
  color: var(--color-light-1);
}

.entry-title.color-light-1-fixed {
  color: var(--color-light-1-fixed);
}

.entry-title.color-light-1-fixed a {
  color: var(--color-light-1-fixed);
}

.entry-category ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}

.entry-category ul li a {
  font-family: var(--font-secondary);
  font-size: 12px;
  font-weight: 600;
  display: inline-block;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.entry-category.style-1 {
  margin-bottom: 15px;
}

.entry-category.style-1 ul li a {
  padding: 4px 10px 3px;
  line-height: 1;
  border: 1px solid;
  border-radius: 27px;
}

.entry-category.style-1 ul li a:hover {
  -webkit-transform: translateX(2px) translateY(2px);
  -ms-transform: translateX(2px) translateY(2px);
  transform: translateX(2px) translateY(2px);
}

.entry-category.style-1.color-dark-1 ul li a {
  color: var(--color-category-dark-1);
  border-color: var(--color-border-dark-1);
  background-color: var(--color-light-1);
  box-shadow: 2px 2px 0px 0px var(--color-border-dark-1);
}

.entry-category.style-1.color-dark-1 ul li a:hover {
  box-shadow: -2px -2px 0px 0px var(--color-border-dark-1);
}

.entry-category.style-1.color-dark-2 ul li a {
  color: var(--color-category-dark-1);
  border-color: var(--color-border-dark-2);
  background-color: var(--color-light-1);
  box-shadow: 2px 2px 0px 0px var(--color-border-dark-2);
}

.entry-category.style-1.color-dark-2 ul li a:hover {
  box-shadow: -2px -2px 0px 0px var(--color-border-dark-2);
}

.entry-category.style-1.color-dark-1-fixed ul li a {
  color: var(--color-category-dark-1-fixed);
  border-color: var(--color-border-dark-1-fixed);
  background-color: var(--color-light-1-fixed);
  box-shadow: 2px 2px 0px 0px var(--color-border-dark-1-fixed);
}

.entry-category.style-1.color-dark-1-fixed ul li a:hover {
  box-shadow: -2px -2px 0px 0px var(--color-border-dark-1-fixed);
}

.entry-category.style-1.color-light-1 ul li a {
  color: var(--color-category-light-1);
  border-color: var(--color-border-light-1);
  background-color: var(--color-dark-1);
  box-shadow: 2px 2px 0px 0px var(--color-border-light-1);
}

.entry-category.style-1.color-light-1 ul li a:hover {
  box-shadow: -2px -2px 0px 0px var(--color-border-light-1);
}

.entry-category.style-1.color-light-1-fixed ul li a {
  color: var(--color-category-light-1-fixed);
  border-color: var(--color-border-light-1-fixed);
  background-color: var(--color-dark-1-fixed);
  box-shadow: 2px 2px 0px 0px var(--color-border-light-1-fixed);
}

.entry-category.style-1.color-light-1-fixed ul li a:hover {
  box-shadow: -2px -2px 0px 0px var(--color-border-light-1-fixed);
}

.entry-category.style-2 {
  margin-bottom: 15px;
}

.entry-category.style-2 ul li a {
  padding: 1px 10px;
  border: 1px solid;
  border-radius: 27px;
}

.entry-category.style-2 ul li a:hover {
  -webkit-transform: translateX(-2px) translateY(-2px);
  -ms-transform: translateX(-2px) translateY(-2px);
  transform: translateX(-2px) translateY(-2px);
}

.entry-category.style-2.color-dark-1 ul li a {
  color: var(--color-category-dark-1);
  border-color: var(--color-border-dark-1);
  background-color: var(--color-light-1);
}

.entry-category.style-2.color-dark-1 ul li a:hover {
  box-shadow: 2px 2px 0px 0px var(--color-border-dark-1);
}

.entry-category.style-2.color-dark-2 ul li a {
  color: var(--color-category-dark-1);
  border-color: var(--color-border-dark-2);
  background-color: var(--color-light-1);
}

.entry-category.style-2.color-dark-2 ul li a:hover {
  box-shadow: 2px 2px 0px 0px var(--color-border-dark-2);
}

.entry-category.style-2.color-dark-1-fixed ul li a {
  color: var(--color-category-dark-1-fixed);
  border-color: var(--color-border-dark-1-fixed);
  background-color: var(--color-light-1-fixed);
}

.entry-category.style-2.color-dark-1-fixed ul li a:hover {
  box-shadow: 2px 2px 0px 0px var(--color-border-dark-1-fixed);
}

.entry-category.style-2.color-light-1 ul li a {
  color: var(--color-category-light-1);
  border-color: var(--color-border-light-1);
  background-color: var(--color-dark-1);
}

.entry-category.style-2.color-light-1 ul li a:hover {
  box-shadow: 2px 2px 0px 0px var(--color-border-light-1);
}

.entry-category.style-2.color-light-1-fixed ul li a {
  color: var(--color-category-light-1-fixed);
  border-color: var(--color-border-light-1-fixed);
  background-color: var(--color-dark-1-fixed);
}

.entry-category.style-2.color-light-1-fixed ul li a:hover {
  box-shadow: 2px 2px 0px 0px var(--color-border-light-1-fixed);
}

.entry-category.style-3 {
  margin-bottom: 12px;
}

.entry-category.style-3.color-light-1 ul li a {
  color: var(--color-category-light-1);
}

.entry-category.style-3.color-light-1 ul li a:hover {
  color: var(--color-primary);
}

.entry-category.style-3.color-light-1-fixed ul li a {
  color: var(--color-category-light-1-fixed);
}

.entry-category.style-3.color-light-1-fixed ul li a:hover {
  color: var(--color-primary);
}

.entry-category.style-3.color-dark-1 ul li a {
  color: var(--color-category-dark-1);
}

.entry-category.style-3.color-dark-1 ul li a:hover {
  color: var(--color-primary);
}

.entry-category.style-3.color-dark-1-fixed ul li a {
  color: var(--color-category-dark-1-fixed);
}

.entry-category.style-3.color-dark-1-fixed ul li a:hover {
  color: var(--color-primary);
}

.entry-category.style-4 {
  margin-bottom: 15px;
}

/* SideBar css start*/
.sidebar-left {
  position: fixed;
  top: 0;
  left: -250px;
  width: 250px;
  height: 100%;
  background-color: white;
  color: black;
  transition: left 0.3s ease;
  z-index: 1000;
  overflow-y: auto;
}

.sidebar-left.open {
  left: 0;
}

.sidebar-left-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  color: black;
  cursor: pointer;
}

.sidebar-left-img {
  margin-left: 0.5rem;
  margin-bottom: 1rem;
}

/* Default (light theme) */
body {
  margin: 0;
  font-family: sans-serif;
  background-color: white;
  color: black;
}

/* Dark theme */
.dark {
  background-color: #121212;
  color: white;
}

.light {
  background-color: white;
  color: black;
}

.sidebar-nav-left {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  /* border-bottom: 1px solid gray; */
}

.sidebar-link-left {
  color: black;
  text-decoration: none;
  padding: 15px;
  border-bottom: 1px solid lightgrey;
  display: block;
}

.sidebar-close {
  position: absolute;
  right: 0;
  background-color: transparent;
  border: none;
  font-size: 2rem;
}

.sidebar-link:hover {
  background-color: #575757;
}

.sidebar-toggle-left {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1001;
}

.side-bar-custom-search-width {
  margin-top: 1.5rem;
}

/* SideBar css end*/

.entry-category.style-4 ul li a {
  padding: 5px 12px 3px;
  border: 0;
  border-radius: 27px;
  line-height: 1;
}

.entry-category.style-4.color-dark-1 ul li a {
  color: var(--color-category-dark-1);
  background-color: var(--color-light-1);
}

.entry-category.style-4.color-dark-1 ul li a:hover {
  color: var(--color-light-1-fixed);
  background-color: var(--color-primary);
}

.entry-category.style-4.color-dark-1-fixed ul li a {
  color: var(--color-category-dark-1-fixed);
  background-color: var(--color-light-1-fixed);
}

.entry-category.style-4.color-dark-1-fixed ul li a:hover {
  color: var(--color-light-1-fixed);
  background-color: var(--color-primary);
}

.entry-category.style-4.color-light-1 ul li a {
  color: var(--color-category-light-1);
  background-color: var(--color-dark-1);
}

.entry-category.style-4.color-light-1 ul li a:hover {
  color: var(--color-light-1-fixed);
  background-color: var(--color-primary);
}

.entry-category.style-4.color-light-1-fixed ul li a {
  color: var(--color-category-light-1-fixed);
  background-color: var(--color-dark-1-fixed);
}

.entry-category.style-4.color-light-1-fixed ul li a:hover {
  color: var(--color-light-1-fixed);
  background-color: var(--color-primary);
}

ul.entry-meta {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
  row-gap: 10px;
}

ul.entry-meta li {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 5px;
  font-size: var(--size-b7);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

ul.entry-meta li:before {
  content: "";
  height: 12px;
  width: 1px;
  position: absolute;
  z-index: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -10px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

ul.entry-meta li a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 5px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

ul.entry-meta li a:hover {
  color: var(--color-primary);
}

ul.entry-meta li a:hover i {
  color: var(--color-primary);
}

ul.entry-meta li img {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: 1px solid;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

ul.entry-meta li.post-author a {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 8px;
}

ul.entry-meta li i {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  line-height: 1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

ul.entry-meta li:last-child:before {
  display: none;
}

ul.entry-meta.style-2 {
  margin-top: 58px;
}

@media (max-width: 991px) {
  ul.entry-meta.style-2 {
    margin-top: 48px;
  }
}

@media (max-width: 767px) {
  ul.entry-meta.style-2 {
    margin-top: 38px;
  }
}

@media (max-width: 575px) {
  ul.entry-meta.style-2 {
    margin-top: 28px;
  }
}

ul.entry-meta.style-2 ul li.post-author {
  gap: 10px;
}

ul.entry-meta.style-2 ul li.post-author img {
  height: 50px;
  width: 50px;
}

ul.entry-meta.style-2 ul li.post-author .meta-content a {
  display: block;
  font-size: 17px;
  color: var(--color-meta-dark-1-fixed);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

ul.entry-meta.style-2 ul li.post-author .meta-content a:hover {
  color: var(--color-primary);
}

ul.entry-meta.style-3 ul {
  display: block;
}

ul.entry-meta.style-3 ul li:before {
  display: none;
}

ul.entry-meta.style-3 ul li.post-author a {
  font-weight: 700;
  color: var(--color-light-1-fixed);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

ul.entry-meta.style-3 ul li.post-author a:hover {
  color: var(--color-primary);
}

ul.entry-meta.color-light-1-fixed li {
  color: var(--color-meta-light-1-fixed);
}

ul.entry-meta.color-light-1-fixed li:before {
  background-color: var(--color-meta-light-1-fixed);
}

ul.entry-meta.color-light-1-fixed li a {
  color: var(--color-meta-light-1-fixed);
}

ul.entry-meta.color-light-1-fixed li a:hover {
  color: var(--color-primary);
}

ul.entry-meta.color-light-1-fixed li img {
  border: 1px solid;
  border-color: var(--color-border-light-1-fixed);
}

ul.entry-meta.color-light-1-fixed li i {
  color: var(--color-meta-light-1-fixed);
}

ul.entry-meta.color-dark-1 li {
  color: var(--color-meta-dark-1);
}

ul.entry-meta.color-dark-1 li:before {
  background-color: var(--color-meta-dark-1);
}

ul.entry-meta.color-dark-1 li a {
  color: var(--color-meta-dark-1);
}

ul.entry-meta.color-dark-1 li a:hover {
  color: var(--color-primary);
}

ul.entry-meta.color-dark-1 li img {
  border-color: var(--color-border-dark-1);
}

ul.entry-meta.color-dark-1 li i {
  color: var(--color-meta-dark-1);
}

ul.entry-meta.color-dark-2 li {
  color: var(--color-meta-dark-1);
}

ul.entry-meta.color-dark-2 li:before {
  background-color: var(--color-meta-dark-1);
}

ul.entry-meta.color-dark-2 li a {
  color: var(--color-meta-dark-1);
}

ul.entry-meta.color-dark-2 li a:hover {
  color: var(--color-primary);
}

ul.entry-meta.color-dark-2 li img {
  border-color: var(--color-border-dark-2);
}

ul.entry-meta.color-dark-2 li i {
  color: var(--color-meta-dark-1);
}

ul.entry-meta.color-dark-1-fixed li {
  color: var(--color-meta-dark-1-fixed);
}

ul.entry-meta.color-dark-1-fixed li:before {
  background-color: var(--color-meta-dark-1-fixed);
}

ul.entry-meta.color-dark-1-fixed li a {
  color: var(--color-meta-dark-1-fixed);
}

ul.entry-meta.color-dark-1-fixed li a:hover {
  color: var(--color-primary);
}

ul.entry-meta.color-dark-1-fixed li img {
  border: 1px solid;
  border-color: var(--color-border-dark-1-fixed);
}

ul.entry-meta.color-dark-1-fixed li i {
  color: var(--color-meta-dark-1-fixed);
}

.axil-social ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px;
}

.axil-social ul li {
  line-height: 1;
}

.axil-social ul li a {
  gap: 20px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--color-body);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.axil-social ul li a i {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  line-height: 1;
  font-size: 16px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.axil-social ul li a:hover {
  color: var(--color-primary);
}

.axil-social.size-large ul li a {
  font-size: 18px;
  height: 32px;
  width: 32px;
}

.axil-social.size-medium ul li a {
  font-size: 17px;
  height: 30px;
  width: 30px;
}

.axil-social.size-small ul li a {
  font-size: 18px;
  height: 36px;
  width: 36px;
}

.axil-social.gap-8 ul {
  gap: 8px;
}

.axil-social.gap-12 ul {
  gap: 12px;
}

.new-social-media-links {
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 2px;
  position: fixed;
  left: 25px;
  z-index: 2000;
}

.social-links-section {
  margin-bottom: 0 !important;
}

.axil-social.social-layout-1 ul li a {
  border-radius: 50%;
  border: 1px solid;
  color: var(--color-light-1-fixed);
}

.axil-social.social-layout-1 ul li a i {
  color: var(--color-light-1-fixed);
}

.bi-link-45deg {
  color: black !important;
  border: 1px solid gray;
  border-radius: 100%;
  font-size: 1.5rem !important;
}

.axil-social.social-layout-1 ul li.reddit a {
  border-color: var(--reddit);
  background-color: var(--reddit);
}

.axil-social.social-layout-1 ul li.reddit a:hover {
  background-color: transparent;
}

.axil-social.social-layout-1 ul li.reddit a:hover i {
  color: var(--reddit);
}

.axil-social.social-layout-1 ul li.snapchat a {
  border-color: var(--snapchat);
  background-color: var(--snapchat);
}

.axil-social.social-layout-1 ul li.snapchat a:hover {
  background-color: transparent;
}

.axil-social.social-layout-1 ul li.snapchat a:hover i {
  color: var(--snapchat);
}

.axil-social.social-layout-1 ul li.threads a {
  border-color: var(--threads);
  background-color: var(--threads);
}

.axil-social.social-layout-1 ul li.threads a:hover {
  background-color: transparent;
}

.axil-social.social-layout-1 ul li.threads a:hover i {
  color: var(--threads);
}

.axil-social.social-layout-1 ul li.whatsapp a {
  border-color: var(--whatsapp);
  background-color: var(--whatsapp);
}

.axil-social.social-layout-1 ul li.whatsapp a:hover {
  background-color: transparent;
}

.axil-social.social-layout-1 ul li.whatsapp a:hover i {
  color: var(--whatsapp);
}

.axil-social.social-layout-1 ul li.facebook a {
  border-color: var(--facebook);
  background-color: var(--facebook);
}

.axil-social.social-layout-1 ul li.facebook a:hover {
  background-color: transparent;
}

.axil-social.social-layout-1 ul li.facebook a:hover i {
  color: var(--facebook);
}

.axil-social.social-layout-1 ul li.instagram a {
  border-color: var(--instagram);
  background-color: var(--instagram);
}

.axil-social.social-layout-1 ul li.instagram a:hover {
  background-color: transparent;
}

.axil-social.social-layout-1 ul li.instagram a:hover i {
  color: var(--instagram);
}

.axil-social.social-layout-1 ul li.twitter a {
  border-color: var(--twitter);
  background-color: var(--twitter);
}

.axil-social.social-layout-1 ul li.twitter a:hover {
  background-color: transparent;
}

.axil-social.social-layout-1 ul li.twitter a:hover i {
  color: var(--twitter);
}

.axil-social.social-layout-1 ul li.pinterest a {
  border-color: var(--pinterest);
  background-color: var(--pinterest);
}

.axil-social.social-layout-1 ul li.pinterest a:hover {
  background-color: transparent;
}

.axil-social.social-layout-1 ul li.pinterest a:hover i {
  color: var(--pinterest);
}

.axil-social.social-layout-1 ul li.linkedin a {
  border-color: var(--linkedin);
  background-color: var(--linkedin);
}

.axil-social.social-layout-1 ul li.linkedin a:hover {
  background-color: transparent;
}

.axil-social.social-layout-1 ul li.linkedin a:hover i {
  color: var(--linkedin);
}

.axil-social.social-layout-1 ul li.vimeo a {
  border-color: var(--vimeo);
  background-color: var(--vimeo);
}

.axil-social.social-layout-1 ul li.vimeo a:hover {
  background-color: transparent;
}

.axil-social.social-layout-1 ul li.vimeo a:hover i {
  color: var(--vimeo);
}

.axil-social.social-layout-1 ul li.tiktok a {
  border-color: var(--tiktok);
  background-color: var(--tiktok);
}

.axil-social.social-layout-1 ul li.tiktok a:hover {
  background-color: transparent;
}

.axil-social.social-layout-1 ul li.tiktok a:hover i {
  color: var(--tiktok);
}

.axil-social.social-layout-1 ul li.youtube a {
  border-color: var(--youtube);
  background-color: var(--youtube);
}

.axil-social.social-layout-1 ul li.youtube a:hover {
  background-color: transparent;
}

.axil-social.social-layout-1 ul li.youtube a:hover i {
  color: var(--youtube);
}

.axil-social.social-layout-1 ul li.dribbble a {
  border-color: var(--dribbble);
  background-color: var(--dribbble);
}

.axil-social.social-layout-1 ul li.dribbble a:hover {
  background-color: transparent;
}

.axil-social.social-layout-1 ul li.dribbble a:hover i {
  color: var(--dribbble);
}

.axil-social.social-layout-1 ul li.mail-fast a {
  border-color: var(--mail-fast);
  background-color: var(--mail-fast);
}

.axil-social.social-layout-1 ul li.mail-fast a:hover {
  background-color: transparent;
}

.axil-social.social-layout-1 ul li.mail-fast a:hover i {
  color: var(--mail-fast);
}

.axil-social.social-layout-2 ul li a {
  border-radius: 50%;
  border: 1px solid;
  border-color: var(--color-border-light-1);
}

.axil-social.social-layout-2 ul li a i {
  color: var(--color-meta-dark-1);
}

.axil-social.social-layout-2 ul li a:hover i {
  color: var(--color-light-1-fixed);
}

.axil-social.social-layout-2 ul li.facebook a:hover {
  border-color: var(--facebook);
  background-color: var(--facebook);
}

.axil-social.social-layout-2 ul li.instagram a:hover {
  border-color: var(--instagram);
  background-color: var(--instagram);
}

.axil-social.social-layout-2 ul li.pinterest a:hover {
  border-color: var(--pinterest);
  background-color: var(--pinterest);
}

.axil-social.social-layout-2 ul li.twitter a:hover {
  border-color: var(--twitter);
  background-color: var(--twitter);
}

.axil-social.social-layout-2 ul li.tiktok a:hover {
  border-color: var(--tiktok);
  background-color: var(--tiktok);
}

.axil-social.social-layout-2 ul li.youtube a:hover {
  border-color: var(--youtube);
  background-color: var(--youtube);
}

.axil-social.social-layout-2 ul li.mail-fast a:hover {
  border-color: var(--mail-fast);
  background-color: var(--mail-fast);
}

.axil-social.social-layout-2.color-dark-1-fixed ul li a {
  border-color: var(--color-border-light-1-fixed);
}

.axil-social.social-layout-2.color-dark-1-fixed ul li a i {
  color: var(--color-meta-dark-1-fixed);
}

.axil-social.social-layout-2.color-dark-1-fixed ul li a:hover i {
  color: var(--color-light-1-fixed);
}

.axil-social.social-layout-3 ul li a {
  padding: 9px 10px;
  min-height: 34px;
  min-width: 122px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.axil-social.social-layout-3 ul li.facebook a {
  color: var(--color-light-1-fixed);
  background-color: var(--facebook);
}

.axil-social.social-layout-3 ul li.twitter a {
  color: var(--color-light-1-fixed);
  background-color: var(--twitter);
}

.axil-social.social-layout-3 ul li.instagram a {
  color: var(--color-light-1-fixed);
  background-color: var(--instagram);
}

.axil-social.social-layout-3 ul li.pinterest a {
  color: var(--color-light-1-fixed);
  background-color: var(--pinterest);
}

.axil-social.social-layout-3 ul li.tiktok a {
  color: var(--color-light-1-fixed);
  background-color: var(--tiktok);
}

.axil-social.social-layout-3 ul li.youtube a {
  color: var(--color-light-1-fixed);
  background-color: var(--youtube);
}

.axil-social.social-layout-3 ul li.mail-fast a {
  color: var(--color-light-1-fixed);
  background-color: var(--mail-fast);
}

.axil-social.social-layout-4 ul li a {
  border-radius: 50%;
}

.axil-social.social-layout-4 ul li a i {
  color: var(--color-meta-dark-1);
}

.axil-social.social-layout-4 ul li a:hover i {
  color: var(--color-light-1-fixed);
}

.axil-social.social-layout-4 ul li.facebook a:hover {
  background-color: var(--facebook);
}

.axil-social.social-layout-4 ul li.instagram a:hover {
  background-color: var(--instagram);
}

.axil-social.social-layout-4 ul li.pinterest a:hover {
  background-color: var(--pinterest);
}

.axil-social.social-layout-4 ul li.twitter a:hover {
  background-color: var(--twitter);
}

.axil-social.social-layout-4 ul li.tiktok a:hover {
  background-color: var(--tiktok);
}

.axil-social.social-layout-4 ul li.youtube a:hover {
  background-color: var(--youtube);
}

.axil-social.social-layout-4 ul li.mail-fast a:hover {
  background-color: var(--mail-fast);
}

.template-pagination {
  margin-top: 40px;
}

.template-pagination ul {
  list-style: none;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  gap: 8px;
  border-radius: 0;
  padding: 0;
  margin: 0;
}

.template-pagination ul li {
  line-height: 1;
  font-size: 16px;
  border: 1px solid;
  border-color: var(--color-border-dark-1);
  background-color: var(--color-light-1);
  height: 40px;
  width: 42px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0;
  border-radius: 2px;
  color: var(--color-dark-1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.template-pagination ul li a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--color-dark-1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.template-pagination ul li:not(.disabled) {
  cursor: pointer;
}

.template-pagination ul li:hover:not(.disabled) {
  background-color: var(--color-btn-bg);
}

.template-pagination ul li:hover:not(.disabled) a {
  color: var(--color-dark-1-fixed);
}

.template-pagination ul li.selected:not(.disabled) {
  background-color: var(--color-btn-bg);
}

.template-pagination ul li.selected:not(.disabled) a {
  color: var(--color-dark-1-fixed);
}

.template-pagination ul li:first-child,
.template-pagination ul li:last-child {
  border: 0;
  background-color: transparent;
}

.template-pagination ul li:first-child:not(.disabled) a,
.template-pagination ul li:last-child:not(.disabled) a {
  color: var(--color-dark-1);
}

.template-pagination ul li:first-child.disabled,
.template-pagination ul li:last-child.disabled {
  color: var(--color-body);
}

.template-pagination ul li:first-child:hover:not(.disabled),
.template-pagination ul li:last-child:hover:not(.disabled) {
  background-color: transparent;
}

.template-pagination ul li:first-child:hover:not(.disabled) a,
.template-pagination ul li:last-child:hover:not(.disabled) a {
  color: var(--color-primary);
}

.template-pagination.pagination-center {
  text-align: center;
}

.template-pagination.pagination-right {
  text-align: right;
}

.template-pagination.pagination-right {
  text-align: right;
}

@media (max-width: 991px) {
  .template-pagination.pagination-right.reverse-lg {
    text-align: left;
  }
}

ul.block-list,
ul.inline-list {
  margin: 0;
  padding: 0;
}

ul.block-list li,
ul.inline-list li {
  list-style: none;
}

.figure-overlay {
  position: relative;
  z-index: 1;
}

.figure-overlay:before {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: -webkit-linear-gradient(bottom,
      #020112 0%,
      rgba(52, 51, 59, 0.55) 40.78%,
      rgba(217, 217, 217, 0) 100%);
  background: linear-gradient(360deg,
      #020112 0%,
      rgba(52, 51, 59, 0.55) 40.78%,
      rgba(217, 217, 217, 0) 100%);
}

.figure-overlay.overlay-whole:before {
  background: rgba(52, 51, 59, 0.3);
}

.both-side-equal {
  height: 100%;
}

.both-side-equal .figure-holder {
  height: 100%;
}

.both-side-equal .link-wrap {
  height: 100%;
}

.both-side-equal img {
  height: 100%;
  object-fit: cover;
}

.child-bottom-border>div .get-border {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid;
}

@media (max-width: 575px) {
  .child-bottom-border>div .get-border {
    margin-bottom: 0;
  }
}

.child-bottom-border>div:last-child .get-border {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.child-bottom-border.color-dark-1-fixed>div .get-border {
  border-color: var(--color-border-dark-1-fixed);
}

/*-------------------------
    Classes
-------------------------*/
.f-f-primary {
  font-family: var(--font-primary);
}

.f-f-secondary {
  font-family: var(--font-secondary);
}

.f-f-awesome {
  font-family: var(--font-awesome);
}

.f-w-400 {
  font-weight: 400;
}

.f-w-500 {
  font-weight: 500;
}

.f-w-600 {
  font-weight: 600;
}

.f-w-700 {
  font-weight: 700;
}

.f-w-800 {
  font-weight: 800;
}

.f-w-900 {
  font-weight: 900;
}

.text-b1 {
  font-size: var(--size-b1);
  line-height: 1.42;
}

.text-b2 {
  font-size: var(--size-b2);
  line-height: 1.66;
}

.text-b3 {
  font-size: var(--size-b3);
  line-height: 1.67;
}

.text-b4 {
  font-size: var(--size-b4);
  line-height: 1.65;
}

.text-b5 {
  font-size: var(--size-b5);
  line-height: 1.75;
}

.text-b6 {
  font-size: var(--size-b6);
  line-height: 1.67;
}

.link-wrap {
  display: block;
}

.bg-color-scandal {
  background-color: var(--color-scandal);
}

.bg-color-mimosa {
  background-color: var(--color-mimosa);
}

.bg-color-selago {
  background-color: var(--color-selago);
}

.bg-color-old-lace {
  background-color: var(--color-old-lace);
}

.bg-color-light-1 {
  background-color: var(--color-bg-light-1);
}

.bg-color-light-2 {
  background-color: var(--color-bg-light-2);
}

.bg-color-light-3 {
  background-color: var(--color-bg-light-3);
}

.bg-color-light-1-fixed {
  background-color: var(--color-light-1-fixed);
}

.bg-color-polar {
  background-color: var(--color-polar);
}

.bg-color-aquamarine {
  background-color: var(--color-aquamarine);
}

.bg-color-perano {
  background-color: var(--color-perano);
}

.bg-color-medium-purple {
  background-color: var(--color-medium-purple);
}

.bg-color-ecru-white {
  background-color: var(--color-ecru-white);
}

.bg-color-woodsmoke {
  background-color: var(--color-woodsmoke);
}

.bg-color-tidal {
  background-color: var(--color-tidal);
}

.bg-color-shamrock {
  background-color: var(--color-shamrock);
}

.box-border-dark-1 {
  border: 1px solid;
  border-color: var(--color-border-dark-1);
}

.box-border-dark-1-fixed {
  border: 1px solid;
  border-color: var(--color-border-dark-1-fixed);
}

.box-border-dark-2 {
  border: 1px solid;
  border-color: var(--color-border-dark-2);
}

.box-border-dark-2-fixed {
  border: 1px solid;
  border-color: var(--color-border-dark-2-fixed);
}

.box-border-light-1-fixed {
  border: 1px solid;
  border-color: var(--color-border-light-1-fixed);
}

.padding-15 {
  padding: 15px;
}

.padding-20 {
  padding: 20px;
}

.padding-24 {
  padding: 24px;
}

.padding-29 {
  padding: 29px;
}

.padding-30 {
  padding: 30px;
}

.padding-40 {
  padding: 40px;
}

@media (max-width: 991px) {
  .pxy-md-30 {
    padding: 30px;
  }

  .pb-md-0 {
    padding-bottom: 0;
  }
}

@media (max-width: 767px) {
  .pxy-sm-20 {
    padding: 20px;
  }
}

@media (max-width: 575px) {
  .px-xs-0 {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 575px) {
  .pxy-xs-10 {
    padding: 10px;
  }
}

.space-top-20 {
  padding-top: 20px;
}

.space-top-25 {
  padding-top: 25px;
}

.space-top-30 {
  padding-top: 30px;
}

.space-top-40 {
  padding-top: 40px;
}

@media (max-width: 767px) {
  .space-top-40 {
    padding-top: 30px;
  }
}

.space-top-50 {
  padding-top: 50px;
}

@media (max-width: 767px) {
  .space-top-50 {
    padding-top: 30px;
  }
}

.space-top-60 {
  padding-top: 60px;
}

@media (max-width: 767px) {
  .space-top-60 {
    padding-top: 50px;
  }
}

.space-bottom-10 {
  padding-bottom: 10px;
}

.space-bottom-30 {
  padding-bottom: 30px;
}

.space-bottom-50 {
  padding-bottom: 50px;
}

@media (max-width: 767px) {
  .space-bottom-50 {
    padding-bottom: 40px;
  }
}

.space-bottom-60 {
  padding-bottom: 60px;
}

@media (max-width: 767px) {
  .space-bottom-60 {
    padding-bottom: 50px;
  }
}

@media (max-width: 991px) {
  .space-bottom-md-30 {
    padding-bottom: 30px;
  }
}

.radius-default {
  border-radius: 8px;
}

.radius-medium {
  border-radius: 6px;
}

.figure-holder.radius-default a.link-wrap,
.figure-holder.radius-default img {
  border-radius: 8px;
}

.figure-holder.radius-default .figure-overlay:before {
  border-radius: 8px;
}

.figure-holder.radius-medium a.link-wrap,
.figure-holder.radius-medium img {
  border-radius: 6px;
}

.figure-holder.radius-medium .figure-overlay:before {
  border-radius: 6px;
}

.figure-holder.radius-top-left.radius-top-right a.link-wrap,
.figure-holder.radius-top-left.radius-top-right img {
  border-radius: 8px 8px 0 0;
}

.figure-holder.radius-top-left.radius-top-right .figure-overlay:before {
  border-radius: 8px 8px 0 0;
}

.figure-holder.radius-medium.radius-top-left.radius-top-right a.link-wrap,
.figure-holder.radius-medium.radius-top-left.radius-top-right img {
  border-radius: 6px 6px 0 0;
}

.figure-holder.radius-medium.radius-top-left.radius-top-right .figure-overlay:before {
  border-radius: 6px 6px 0 0;
}

.transition-default {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.img-height-100 img {
  height: 100%;
}

.height-auto {
  height: auto !important;
}

/*-------------------------
    Animation
-------------------------*/

.figure-scale-animation .figure-holder {
  overflow: hidden;
}

.figure-scale-animation .figure-holder img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: var(--transition-figure);
  transition: var(--transition-figure);
}

.figure-scale-animation:hover .figure-holder img {
  -webkit-transform: scale(1.08);
  -ms-transform: scale(1.08);
  transform: scale(1.08);
}

.shadow-style-1.box-shadow-large:hover {
  -webkit-transform: translateX(5px) translateY(5px);
  -ms-transform: translateX(5px) translateY(5px);
  transform: translateX(5px) translateY(5px);
}

.shadow-style-1.box-shadow-medium:hover {
  -webkit-transform: translateX(4px) translateY(4px);
  -ms-transform: translateX(4px) translateY(4px);
  transform: translateX(4px) translateY(4px);
}

.shadow-style-1.box-shadow-default:hover {
  -webkit-transform: translateX(3px) translateY(3px);
  -ms-transform: translateX(3px) translateY(3px);
  transform: translateX(3px) translateY(3px);
}

.shadow-style-1.box-shadow-small:hover {
  -webkit-transform: translateX(2px) translateY(2px);
  -ms-transform: translateX(2px) translateY(2px);
  transform: translateX(2px) translateY(2px);
}

.shadow-style-1.box-border-dark-1.no-border {
  border: 0;
}

.shadow-style-1.box-border-dark-1.box-shadow-large {
  box-shadow: 5px 5px 0px 0px var(--color-border-dark-1);
}

.shadow-style-1.box-border-dark-1.box-shadow-large:hover {
  box-shadow: -5px -5px 0px 0px var(--color-border-dark-1);
}

.shadow-style-1.box-border-dark-1.box-shadow-large.shadow-fixed {
  box-shadow: 5px 5px 0px 0px var(--color-border-dark-1-fixed);
}

.shadow-style-1.box-border-dark-1.box-shadow-large.shadow-fixed:hover {
  box-shadow: -5px -5px 0px 0px var(--color-border-dark-1-fixed);
}

.shadow-style-1.box-border-dark-1.box-shadow-medium {
  box-shadow: 4px 4px 0px 0px var(--color-border-dark-1);
}

.shadow-style-1.box-border-dark-1.box-shadow-medium:hover {
  box-shadow: -4px -4px 0px 0px var(--color-border-dark-1);
}

.shadow-style-1.box-border-dark-1.box-shadow-medium.shadow-fixed {
  box-shadow: 4px 4px 0px 0px var(--color-border-dark-1-fixed);
}

.shadow-style-1.box-border-dark-1.box-shadow-medium.shadow-fixed:hover {
  box-shadow: -4px -4px 0px 0px var(--color-border-dark-1-fixed);
}

.shadow-style-1.box-border-dark-1.box-shadow-default {
  box-shadow: 3px 3px 0px 0px var(--color-border-dark-1);
}

.shadow-style-1.box-border-dark-1.box-shadow-default:hover {
  box-shadow: -3px -3px 0px 0px var(--color-border-dark-1);
}

.shadow-style-1.box-border-dark-1.box-shadow-default.shadow-fixed {
  box-shadow: 3px 3px 0px 0px var(--color-border-dark-1-fixed);
}

.shadow-style-1.box-border-dark-1.box-shadow-default.shadow-fixed:hover {
  box-shadow: -3px -3px 0px 0px var(--color-border-dark-1-fixed);
}

.shadow-style-1.box-border-dark-1.box-shadow-small {
  box-shadow: 2px 2px 0px 0px var(--color-border-dark-1);
}

.shadow-style-1.box-border-dark-1.box-shadow-small:hover {
  box-shadow: -2px -2px 0px 0px var(--color-border-dark-1);
}

.shadow-style-1.box-border-dark-1.box-shadow-small.shadow-fixed {
  box-shadow: 2px 2px 0px 0px var(--color-border-dark-1-fixed);
}

.shadow-style-1.box-border-dark-1.box-shadow-small.shadow-fixed:hover {
  box-shadow: -2px -2px 0px 0px var(--color-border-dark-1-fixed);
}

.shadow-style-1.box-border-dark-2.no-border {
  border: 0;
}

.shadow-style-1.box-border-dark-2.box-shadow-large {
  box-shadow: 5px 5px 0px 0px var(--color-border-dark-2);
}

.shadow-style-1.box-border-dark-2.box-shadow-large:hover {
  box-shadow: -5px -5px 0px 0px var(--color-border-dark-2);
}

.shadow-style-1.box-border-dark-2.box-shadow-large.shadow-fixed {
  box-shadow: 5px 5px 0px 0px var(--color-border-dark-2-fixed);
}

.shadow-style-1.box-border-dark-2.box-shadow-large.shadow-fixed:hover {
  box-shadow: -5px -5px 0px 0px var(--color-border-dark-2-fixed);
}

.shadow-style-1.box-border-dark-2.box-shadow-medium {
  box-shadow: 4px 4px 0px 0px var(--color-border-dark-2);
}

.shadow-style-1.box-border-dark-2.box-shadow-medium:hover {
  box-shadow: -4px -4px 0px 0px var(--color-border-dark-2);
}

.shadow-style-1.box-border-dark-2.box-shadow-medium.shadow-fixed {
  box-shadow: 4px 4px 0px 0px var(--color-border-dark-2-fixed);
}

.shadow-style-1.box-border-dark-2.box-shadow-medium.shadow-fixed:hover {
  box-shadow: -4px -4px 0px 0px var(--color-border-dark-2-fixed);
}

.shadow-style-1.box-border-dark-2.box-shadow-default {
  box-shadow: 3px 3px 0px 0px var(--color-border-dark-2);
}

.shadow-style-1.box-border-dark-2.box-shadow-default:hover {
  box-shadow: -3px -3px 0px 0px var(--color-border-dark-2);
}

.shadow-style-1.box-border-dark-2.box-shadow-default.shadow-fixed {
  box-shadow: 3px 3px 0px 0px var(--color-border-dark-2-fixed);
}

.shadow-style-1.box-border-dark-2.box-shadow-default.shadow-fixed:hover {
  box-shadow: -3px -3px 0px 0px var(--color-border-dark-2-fixed);
}

.shadow-style-1.box-border-dark-2.box-shadow-small {
  box-shadow: 2px 2px 0px 0px var(--color-border-dark-2);
}

.shadow-style-1.box-border-dark-2.box-shadow-small:hover {
  box-shadow: -2px -2px 0px 0px var(--color-border-dark-2);
}

.shadow-style-1.box-border-dark-2.box-shadow-small.shadow-fixed {
  box-shadow: 2px 2px 0px 0px var(--color-border-dark-2-fixed);
}

.shadow-style-1.box-border-dark-2.box-shadow-small.shadow-fixed:hover {
  box-shadow: -2px -2px 0px 0px var(--color-border-dark-2-fixed);
}

.shadow-style-2.box-shadow-large:hover {
  -webkit-transform: translateX(-5px) translateY(-5px);
  -ms-transform: translateX(-5px) translateY(-5px);
  transform: translateX(-5px) translateY(-5px);
}

.shadow-style-2.box-shadow-medium:hover {
  -webkit-transform: translateX(-4px) translateY(-4px);
  -ms-transform: translateX(-4px) translateY(-4px);
  transform: translateX(-4px) translateY(-4px);
}

.shadow-style-2.box-shadow-default:hover {
  -webkit-transform: translateX(-3px) translateY(-3px);
  -ms-transform: translateX(-3px) translateY(-3px);
  transform: translateX(-3px) translateY(-3px);
}

.shadow-style-2.box-shadow-small:hover {
  -webkit-transform: translateX(-2px) translateY(-2px);
  -ms-transform: translateX(-2px) translateY(-2px);
  transform: translateX(-2px) translateY(-2px);
}

.shadow-style-2.box-border-dark-1.no-border {
  border: 0;
}

.shadow-style-2.box-border-dark-1.box-shadow-large:hover {
  box-shadow: 5px 5px 0px 0px var(--color-border-dark-1);
}

.shadow-style-2.box-border-dark-1.box-shadow-large.shadow-fixed:hover {
  box-shadow: 5px 5px 0px 0px var(--color-border-dark-1-fixed);
}

.shadow-style-2.box-border-dark-1.box-shadow-medium:hover {
  box-shadow: 4px 4px 0px 0px var(--color-border-dark-1);
}

.shadow-style-2.box-border-dark-1.box-shadow-medium.shadow-fixed:hover {
  box-shadow: 4px 4px 0px 0px var(--color-border-dark-1-fixed);
}

.shadow-style-2.box-border-dark-1.box-shadow-default:hover {
  box-shadow: 3px 3px 0px 0px var(--color-border-dark-1);
}

.shadow-style-2.box-border-dark-1.box-shadow-default.shadow-fixed:hover {
  box-shadow: 3px 3px 0px 0px var(--color-border-dark-1-fixed);
}

.shadow-style-2.box-border-dark-1.box-shadow-small:hover {
  box-shadow: 2px 2px 0px 0px var(--color-border-dark-1);
}

.shadow-style-2.box-border-dark-1.box-shadow-small.shadow-fixed:hover {
  box-shadow: 2px 2px 0px 0px var(--color-border-dark-1-fixed);
}

.shadow-style-2.box-border-dark-2.no-border {
  border: 0;
}

.shadow-style-2.box-border-dark-2.box-shadow-large:hover {
  box-shadow: 5px 5px 0px 0px var(--color-border-dark-2);
}

.shadow-style-2.box-border-dark-2.box-shadow-large.shadow-fixed:hover {
  box-shadow: 5px 5px 0px 0px var(--color-border-dark-2-fixed);
}

.shadow-style-2.box-border-dark-2.box-shadow-medium:hover {
  box-shadow: 4px 4px 0px 0px var(--color-border-dark-2);
}

.shadow-style-2.box-border-dark-2.box-shadow-medium.shadow-fixed:hover {
  box-shadow: 4px 4px 0px 0px var(--color-border-dark-2-fixed);
}

.shadow-style-2.box-border-dark-2.box-shadow-default:hover {
  box-shadow: 3px 3px 0px 0px var(--color-border-dark-2);
}

.shadow-style-2.box-border-dark-2.box-shadow-default.shadow-fixed:hover {
  box-shadow: 3px 3px 0px 0px var(--color-border-dark-2-fixed);
}

.shadow-style-2.box-border-dark-2.box-shadow-small:hover {
  box-shadow: 2px 2px 0px 0px var(--color-border-dark-2);
}

.shadow-style-2.box-border-dark-2.box-shadow-small.shadow-fixed:hover {
  box-shadow: 2px 2px 0px 0px var(--color-border-dark-2-fixed);
}

.animated-border {
  position: relative;
  z-index: 1;
}

.animated-border:before {
  height: 100%;
  width: 100%;
  border: 1px solid;
  border-color: var(--color-border-dark-2);
  border-radius: 8px;
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.animated-border.border-style-large:before {
  -webkit-transform: translateX(0) translateY(0);
  -ms-transform: translateX(0) translateY(0);
  transform: translateX(0) translateY(0);
}

.animated-border.border-style-large:hover:before {
  -webkit-transform: translateX(7px) translateY(7px);
  -ms-transform: translateX(7px) translateY(7px);
  transform: translateX(7px) translateY(7px);
}

.animated-border.border-style-large:hover .animation-child {
  -webkit-transform: translateX(-3px) translateY(-3px);
  -ms-transform: translateX(-3px) translateY(-3px);
  transform: translateX(-3px) translateY(-3px);
}

.animated-border.border-style-medium:before {
  -webkit-transform: translateX(0) translateY(0);
  -ms-transform: translateX(0) translateY(0);
  transform: translateX(0) translateY(0);
}

.animated-border.border-style-medium:hover:before {
  -webkit-transform: translateX(5px) translateY(5px);
  -ms-transform: translateX(5px) translateY(5px);
  transform: translateX(5px) translateY(5px);
}

.animated-border.border-style-medium:hover .animation-child {
  -webkit-transform: translateX(-2px) translateY(-2px);
  -ms-transform: translateX(-2px) translateY(-2px);
  transform: translateX(-2px) translateY(-2px);
}

.animated-border.border-style-small:before {
  -webkit-transform: translateX(0) translateY(0);
  -ms-transform: translateX(0) translateY(0);
  transform: translateX(0) translateY(0);
}

.animated-border.border-style-small:hover:before {
  -webkit-transform: translateX(3px) translateY(3px);
  -ms-transform: translateX(3px) translateY(3px);
  transform: translateX(3px) translateY(3px);
}

.animated-border.border-style-small:hover .animation-child {
  -webkit-transform: translateX(-1px) translateY(-1px);
  -ms-transform: translateX(-1px) translateY(-1px);
  transform: translateX(-1px) translateY(-1px);
}

.underline-animation a {
  position: relative;
  display: inline;
  background-image: -webkit-linear-gradient(left,
      currentColor 0%,
      currentColor 100%);
  background-image: linear-gradient(to right,
      currentColor 0%,
      currentColor 100%);
  background-size: 0px 2px;
  background-position: 0px 95%;
  padding: 0.1% 0px;
  background-repeat: no-repeat;
  color: inherit;
  -webkit-transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
  transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
}

.underline-animation a:hover {
  background-size: 100% 2px;
}

/*=======================================================================
2. Element Styles
=========================================================================*/
/*-------------------------
    Back To Top  
--------------------------*/
.back-to-top {
  position: fixed;
  bottom: -40px;
  right: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: var(--color-primary);
  color: #fff;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-decoration: none;
  border-radius: 50%;
  opacity: 0;
  font-size: 24px;
  -webkit-transform: scale(0.3);
  -ms-transform: scale(0.3);
  transform: scale(0.3);
  box-shadow: 4px 4px 10px rgba(var(--color-black-rgb), 0.2);
  z-index: 9;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

@media only screen and (max-width: 575px) {
  .back-to-top {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

.back-to-top:focus {
  color: var(--color-white);
}

.back-to-top.show {
  bottom: 40px;
  right: 40px;
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

@media only screen and (max-width: 575px) {
  .back-to-top.show {
    bottom: 10px;
    right: 10px;
  }
}

.back-to-top.show:hover {
  color: var(--color-white);
  bottom: 30px;
  opacity: 1;
}

@media only screen and (max-width: 575px) {
  .back-to-top.show:hover {
    bottom: 10px;
  }
}

/*-------------------------
    Breadcrumb
-------------------------*/
.breadcrumb-wrap-layout1 {
  padding: 20px 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 60px;
}

.breadcrumb-layout1 .breadcrumb {
  margin: 0;
}

.breadcrumb-layout1 .breadcrumb .breadcrumb-item a {
  color: var(--color-meta-dark-1-fixed);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.breadcrumb-layout1 .breadcrumb .breadcrumb-item a:hover {
  color: var(--color-primary);
}

.breadcrumb-layout1 .breadcrumb .breadcrumb-item.active {
  color: var(--color-dark-1-fixed);
}

/*-------------------------
    Button
-------------------------*/
.axil-btn {
  border-radius: 8px;
  min-height: 50px;
  font-family: var(--font-secondary);
  font-size: 16px;
  font-weight: 500;
  border: 1px solid;
  outline: none;
  padding: 5px 30px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-column-gap: 7px;
  -moz-column-gap: 7px;
  column-gap: 7px;
  cursor: pointer;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

@media (max-width: 991px) {
  .axil-btn {
    min-height: 45px;
    padding: 5px 25px;
  }
}

@media (max-width: 575px) {
  .axil-btn {
    min-height: 40px;
    padding: 5px 20px;
  }
}

.axil-btn.axil-btn-bold {
  font-weight: 700;
}

.axil-btn.axil-btn-semi-bold {
  font-weight: 600;
}

.axil-btn.axil-btn-large {
  min-height: 55px;
  padding: 5px 32px;
}

@media (max-width: 991px) {
  .axil-btn.axil-btn-large {
    min-height: 50px;
    padding: 5px 28px;
  }
}

@media (max-width: 575px) {
  .axil-btn.axil-btn-large {
    font-size: 15px;
    min-height: 45px;
    padding: 5px 24px;
  }
}

.axil-btn.axil-btn-small {
  min-height: 45px;
  padding: 5px 26px;
}

@media (max-width: 991px) {
  .axil-btn.axil-btn-small {
    min-height: 40px;
    padding: 5px 22px;
  }
}

@media (max-width: 575px) {
  .axil-btn.axil-btn-small {
    font-size: 15px;
    min-height: 35px;
    padding: 5px 18px;
  }
}

.axil-btn.axil-btn-fill {
  color: var(--color-dark-1-fixed);
  background-color: var(--color-btn-bg);
  border-color: var(--color-border-dark-1-fixed);
}

.axil-btn.axil-btn-fill:hover {
  color: var(--color-dark-1-fixed);
  background-color: transparent;
  -webkit-transform: translateX(-3px) translateY(-3px);
  -ms-transform: translateX(-3px) translateY(-3px);
  transform: translateX(-3px) translateY(-3px);
  box-shadow: 3px 3px 0px 0px var(--color-border-dark-1);
}

.axil-btn.axil-btn-fill.btn-color-alter {
  color: var(--color-dark-1-fixed);
  border-color: var(--color-border-dark-1);
}

.axil-btn.axil-btn-fill.btn-color-alter:hover {
  color: var(--color-dark-1);
}

.axil-btn.axil-btn-fill.shadow-fixed:hover {
  box-shadow: 3px 3px 0px 0px var(--color-border-dark-1-fixed);
}

.axil-btn.axil-btn-fill.axil-btn-light {
  color: var(--color-dark-1);
  background-color: var(--color-light-1);
  border-color: var(--color-border-light-1);
}

.axil-btn.axil-btn-fill.axil-btn-light:hover {
  background-color: transparent;
  color: var(--color-light-1);
  -webkit-transform: translateX(-3px) translateY(-3px);
  -ms-transform: translateX(-3px) translateY(-3px);
  transform: translateX(-3px) translateY(-3px);
  box-shadow: 3px 3px 0px 0px var(--color-border-light-1);
}

.axil-btn.axil-btn-fill.axil-btn-light-fixed {
  color: var(--color-dark-1-fixed);
  background-color: var(--color-light-1-fixed);
  border-color: var(--color-border-light-1-fixed);
}

.axil-btn.axil-btn-fill.axil-btn-light-fixed:hover {
  background-color: transparent;
  color: var(--color-light-1-fixed);
  -webkit-transform: translateX(-3px) translateY(-3px);
  -ms-transform: translateX(-3px) translateY(-3px);
  transform: translateX(-3px) translateY(-3px);
  box-shadow: 3px 3px 0px 0px var(--color-border-light-1-fixed);
}

.axil-btn.axil-btn-fill.axil-btn-dark {
  background-color: var(--color-dark-1);
  color: var(--color-light-1);
  border-color: var(--color-border-dark-1);
}

.axil-btn.axil-btn-fill.axil-btn-dark:hover {
  background-color: transparent;
  color: var(--color-dark-1);
  -webkit-transform: translateX(-3px) translateY(-3px);
  -ms-transform: translateX(-3px) translateY(-3px);
  transform: translateX(-3px) translateY(-3px);
  box-shadow: 3px 3px 0px 0px var(--color-border-dark-1);
}

.axil-btn.axil-btn-fill.axil-btn-dark-fixed {
  background-color: var(--color-dark-1-fixed);
  color: var(--color-light-1-fixed);
  border-color: var(--color-border-dark-1-fixed);
}

.axil-btn.axil-btn-fill.axil-btn-dark-fixed:hover {
  background-color: transparent;
  color: var(--color-dark-1-fixed);
  -webkit-transform: translateX(-3px) translateY(-3px);
  -ms-transform: translateX(-3px) translateY(-3px);
  transform: translateX(-3px) translateY(-3px);
  box-shadow: 3px 3px 0px 0px var(--color-border-dark-1-fixed);
}

.axil-btn.axil-btn-ghost {
  color: var(--color-dark-1-fixed);
  background-color: transparent;
  border-color: var(--color-border-dark-1-fixed);
}

.axil-btn.axil-btn-ghost:hover {
  color: var(--color-dark-1-fixed);
  background-color: var(--color-btn-bg);
  -webkit-transform: translateX(-3px) translateY(-3px);
  -ms-transform: translateX(-3px) translateY(-3px);
  transform: translateX(-3px) translateY(-3px);
  box-shadow: 3px 3px 0px 0px var(--color-border-dark-1);
}

.axil-btn.axil-btn-ghost.btn-color-alter {
  color: var(--color-dark-1);
  border-color: var(--color-border-dark-1);
}

.axil-btn.axil-btn-ghost.btn-color-alter:hover {
  color: var(--color-dark-1-fixed);
}

.axil-btn.axil-btn-ghost.shadow-fixed:hover {
  box-shadow: 3px 3px 0px 0px var(--color-border-dark-1-fixed);
}

.axil-btn.axil-btn-ghost.axil-btn-dark {
  color: var(--color-dark-1);
  background-color: transparent;
  border-color: var(--color-border-dark-1);
}

.axil-btn.axil-btn-ghost.axil-btn-dark:hover {
  color: var(--color-dark-1);
  background-color: transparent;
  border-color: var(--color-border-dark-1);
  -webkit-transform: translateX(-3px) translateY(-3px);
  -ms-transform: translateX(-3px) translateY(-3px);
  transform: translateX(-3px) translateY(-3px);
  box-shadow: 3px 3px 0px 0px var(--color-border-dark-1);
}

.axil-btn.axil-btn-ghost.axil-btn-dark-fixed {
  color: var(--color-dark-1-fixed);
  background-color: transparent;
  border-color: var(--color-border-dark-1-fixed);
}

.axil-btn.axil-btn-ghost.axil-btn-dark-fixed:hover {
  color: var(--color-dark-1-fixed);
  background-color: transparent;
  border-color: var(--color-border-dark-1-fixed);
  -webkit-transform: translateX(-3px) translateY(-3px);
  -ms-transform: translateX(-3px) translateY(-3px);
  transform: translateX(-3px) translateY(-3px);
  box-shadow: 3px 3px 0px 0px var(--color-border-dark-1-fixed);
}

.axil-btn.axil-btn-ghost.axil-btn-light {
  color: var(--color-light-1);
  background-color: transparent;
  border-color: var(--color-border-light-1);
}

.axil-btn.axil-btn-ghost.axil-btn-light:hover {
  background-color: var(--color-light-1);
  color: var(--color-dark-1);
  -webkit-transform: translateX(-3px) translateY(-3px);
  -ms-transform: translateX(-3px) translateY(-3px);
  transform: translateX(-3px) translateY(-3px);
  box-shadow: 3px 3px 0px 0px var(--color-border-light-1);
}

.axil-btn.axil-btn-ghost.axil-btn-light-fixed {
  color: var(--color-light-1-fixed);
  background-color: transparent;
  border-color: var(--color-border-light-1-fixed);
}

.axil-btn.axil-btn-ghost.axil-btn-light-fixed:hover {
  background-color: var(--color-light-1-fixed);
  color: var(--color-dark-1);
  -webkit-transform: translateX(-3px) translateY(-3px);
  -ms-transform: translateX(-3px) translateY(-3px);
  transform: translateX(-3px) translateY(-3px);
  box-shadow: 3px 3px 0px 0px var(--color-border-light-1-fixed);
}

.axil-btn .icon-holder {
  line-height: 1;
  font-size: 24px;
  margin-top: 2px;
}

.axil-btn.icon-size-small .icon-holder {
  font-size: 18px;
  margin-top: 3px;
}

.axil-btnbutton i {
  line-height: 1;
  font-size: 23px;
  margin-top: 2px;
}

.btn-text {
  font-weight: 500;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.btn-text:hover {
  color: var(--color-primary);
}

.btn-text .icon-holder {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  font-size: 24px;
  border: 1px solid;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.btn-text .icon-holder i {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.btn-text.color-dark-1 {
  color: var(--color-dark-1);
}

.btn-text.color-dark-1 .icon-holder {
  box-shadow: 2px 0 0 0 var(--color-border-dark-1);
  background-color: var(--color-light-1);
  border-color: var(--color-border-dark-1);
  color: var(--color-dark-1);
}

.btn-text.color-dark-1:hover {
  color: var(--color-primary);
}

.btn-text.color-dark-1:hover .icon-holder {
  box-shadow: -2px 0 0 0 var(--color-border-dark-1);
  color: var(--color-dark-1);
}

.btn-text.color-dark-1-fixed {
  color: var(--color-dark-1-fixed);
}

.btn-text.color-dark-1-fixed .icon-holder {
  box-shadow: 2px 0 0 0 var(--color-border-dark-1-fixed);
  border-color: var(--color-border-dark-1-fixed);
  background-color: var(--color-light-1-fixed);
  color: var(--color-dark-1-fixed);
}

.btn-text.color-dark-1-fixed:hover {
  color: var(--color-primary);
}

.btn-text.color-dark-1-fixed:hover .icon-holder {
  box-shadow: -2px 0 0 0 var(--color-border-dark-1-fixed);
  color: var(--color-dark-1-fixed);
}

.btn-text:hover .icon-holder {
  -webkit-transform: translateX(2px);
  -ms-transform: translateX(2px);
  transform: translateX(2px);
}

.btn-text:hover .icon-holder i {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

.play-btn {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--color-light-1-fixed);
  border-radius: 50%;
  margin: 0 auto;
  color: var(--color-dark-1-fixed);
  -webkit-animation: blinking 2000ms linear infinite;
  animation: blinking 2000ms linear infinite;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

@media (max-width: 767px) {
  .play-btn {
    top: 15px;
    right: 15px;
    left: inherit;
    -webkit-transform: translateY(0) translateX(0);
    -ms-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
}

.play-btn:before {
  content: "";
  position: absolute;
  border-radius: 50%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.play-btn:hover {
  background-color: var(--color-primary);
  color: var(--color-light-1-fixed);
}

.play-btn i {
  font-weight: 900;
}

.play-btn.style-2 {
  background-color: rgba(var(--color-white-rgb-fixed), 0.7);
}

.play-btn.style-2:hover {
  background-color: rgba(var(--color-primary-rgb), 0.7);
}

.play-btn.not-animation {
  -webkit-animation: none;
  animation: none;
}

.play-btn.size-large {
  font-size: 50px;
  height: 96px;
  width: 96px;
}

@media (max-width: 1199px) {
  .play-btn.size-large {
    font-size: 45px;
    height: 86px;
    width: 86px;
  }
}

@media (max-width: 991px) {
  .play-btn.size-large {
    font-size: 40px;
    height: 76px;
    width: 76px;
  }
}

@media (max-width: 767px) {
  .play-btn.size-large {
    font-size: 35px;
    height: 66px;
    width: 66px;
  }
}

@media (max-width: 575px) {
  .play-btn.size-large {
    font-size: 30px;
    height: 56px;
    width: 56px;
  }
}

@media (max-width: 480px) {
  .play-btn.size-large {
    font-size: 25px;
    height: 46px;
    width: 46px;
  }
}

.play-btn.size-large:before {
  height: 102px;
  width: 102px;
  top: -3px;
  left: -3px;
}

@media (max-width: 1199px) {
  .play-btn.size-large:before {
    height: 92px;
    width: 92px;
  }
}

@media (max-width: 991px) {
  .play-btn.size-large:before {
    height: 82px;
    width: 82px;
  }
}

@media (max-width: 767px) {
  .play-btn.size-large:before {
    height: 72px;
    width: 72px;
  }
}

@media (max-width: 575px) {
  .play-btn.size-large:before {
    height: 62px;
    width: 62px;
  }
}

@media (max-width: 480px) {
  .play-btn.size-large:before {
    height: 52px;
    width: 52px;
  }
}

.play-btn.size-regular {
  font-size: 42px;
  height: 74px;
  width: 74px;
}

@media (max-width: 767px) {
  .play-btn.size-regular {
    font-size: 36px;
    height: 64px;
    width: 64px;
  }
}

.play-btn.size-regular:before {
  height: 80px;
  width: 80px;
  top: -3px;
  left: -3px;
}

@media (max-width: 767px) {
  .play-btn.size-regular:before {
    height: 70px;
    width: 70px;
  }
}

.play-btn.size-medium {
  font-size: 36px;
  height: 56px;
  width: 56px;
}

@media (max-width: 767px) {
  .play-btn.size-medium {
    font-size: 30px;
    height: 46px;
    width: 46px;
  }
}

.play-btn.size-medium:before {
  height: 60px;
  width: 60px;
  top: -2px;
  left: -2px;
}

@media (max-width: 767px) {
  .play-btn.size-medium:before {
    height: 50px;
    width: 50px;
  }
}

.play-btn.size-small {
  font-size: 21px;
  height: 36px;
  width: 36px;
}

.play-btn.size-small:before {
  height: 38px;
  width: 38px;
  top: -1px;
  left: -1px;
}

.play-btn.size-small i {
  margin-left: 3px;
}

@media (max-width: 767px) {
  .play-btn.position-center {
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
  }
}

.play-btn.position-top-right {
  top: 30px;
  right: 30px;
  left: inherit;
  -webkit-transform: translateY(0) translateX(0);
  -ms-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
}

@-webkit-keyframes blinking {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes blinking {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

/*-------------------------
    Slick
-------------------------*/
.slick-slide {
  padding: 0px 15px 6px;
}

.slick-slider .slick-arrow {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex !important;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  font-size: 24px;
  border: 1px solid;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: absolute;
  z-index: 99;
  background-color: var(--color-ecru-white);
}

.slick-slider .slick-arrow i {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
}

.slick-slider .slick-arrow.slick_next:hover {
  box-shadow: -2px 0 0 0 var(--color-border-dark-1-fixed);
}

.slick-slider .slick-arrow.slick_prev:hover {
  box-shadow: 2px 0 0 0 var(--color-border-dark-1-fixed);
}

.heroBanner {
  margin-top: 40px;
}

.heroBanner .slick-arrow.slick_next {
  bottom: 30px;
  right: 40px;
}

.heroBanner .slick-arrow.slick_prev {
  bottom: 30px;
  right: 90px;
}

.heroBannerLeft img.image-mask {
  width: 100%;
  height: 612px;
  object-fit: cover;
}

.heroBannerRight .figure-holder img {
  width: 100%;
  height: 380px;
  object-fit: cover;
}

.bannerBottom .slick-arrow.slick_next {
  top: 43%;
  right: 0px;
}

.bannerBottom .slick-arrow.slick_prev {
  top: 43%;
  left: 0px;
}

.bannerBottom .slick-slide .figure-holder img {
  width: 100%;
  height: 280px;
  object-fit: cover;
}

.latestStoryLeft .figure-holder img {
  height: 584px;
  width: 100%;
  object-fit: cover;
}

.latestStoryRight .post-box:not(:first-child) .figure-holder img {
  width: 100px;
  height: 80px;
  object-fit: cover;
}

.latestStoryRight .post-box .figure-holder img {
  width: 100%;
  height: auto;
  max-height: 280px;
  object-fit: cover;
}

.latestStoryRight .post-box:first-child .entry-title.color-dark-1,
.latestStoryRight .post-box:first-child .entry-title.color-dark-1 a {
  color: var(--color-light-1);
}

.latestStoryRight .post-box:first-child ul.entry-meta.color-dark-1 li,
.latestStoryRight .post-box:first-child ul.entry-meta.color-dark-1 li i {
  color: var(--color-light-1) !important;
}

.footer-menu.double ul li {
  display: block;
  width: 100%;
  margin-right: 2%;
  margin-bottom: 14px !important;
}

.trendingTopic .slick-arrow.slick_next {
  top: 60px;
  left: -110px;
}

.trendingTopic .slick-arrow.slick_prev {
  top: 60px;
  left: -170px;
}

.topColumnist .slick-arrow.slick_next {
  top: -70px;
  right: 0px;
}

.topColumnist .slick-arrow.slick_prev {
  top: -70px;
  right: 50px;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.slide {
  height: 100%;
}

.slick-slide>DIV:first-child {
  height: 100%;
}

.slick-slider.gutter-30 .slick-list {
  margin: 0 -15px;
}

.slick-slider.gutter-30 .slick-list .slick-slide {
  padding: 0 15px;
}

.slick-slider.gutter-6 .slick-list {
  margin: 0 -6px;
}

.slick-slider.gutter-6 .slick-list .slick-slide {
  padding: 0 6px;
}

.slick-slider.slick-initialized {
  border-radius: 8px;
}

.outer-top-5 .slick-list .slick-track {
  margin-top: 5px;
}

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

button.slick-arrow:before {
  display: none;
}

button.slick-arrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 1;
  height: 80px;
  width: 80px;
  background-color: var(--color-light-1);
  border-radius: 50%;
  padding: 10px;
  outline: 0;
  border: 1px solid;
  border-color: var(--color-border-dark-2);
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

button.slick-arrow:after {
  font-family: "icomoon";
  opacity: 1;
  color: var(--color-dark-1);
  font-size: 46px;
  line-height: 1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

@media (max-width: 1199px) {
  button.slick-arrow {
    height: 70px;
    width: 70px;
  }

  button.slick-arrow:after {
    font-size: 40px;
  }
}

@media (max-width: 991px) {
  button.slick-arrow {
    height: 60px;
    width: 60px;
  }

  button.slick-arrow:after {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  button.slick-arrow {
    height: 40px;
    width: 40px;
  }

  button.slick-arrow:after {
    font-size: 24px;
  }
}

button.slick-prev {
  box-shadow: -4px 0 0 0 var(--color-border-dark-2);
  left: -70px;
}

@media (max-width: 1399px) {
  button.slick-prev {
    left: -30px;
  }
}

@media (max-width: 575px) {
  button.slick-prev {
    left: 0;
  }
}

button.slick-prev:after {
  content: "\ed4b";
}

button.slick-prev:hover {
  -webkit-transform: translate(-4px, -50%);
  -ms-transform: translate(-4px, -50%);
  transform: translate(-4px, -50%);
  box-shadow: 4px 0 0 0 var(--color-border-dark-2);
}

button.slick-next {
  box-shadow: 4px 0 0 0 var(--color-border-dark-2);
  right: -70px;
}

@media (max-width: 1399px) {
  button.slick-next {
    right: -30px;
  }
}

@media (max-width: 575px) {
  button.slick-next {
    right: 0;
  }
}

button.slick-next:after {
  content: "\ed54";
}

button.slick-next:hover {
  -webkit-transform: translate(4px, -50%);
  -ms-transform: translate(4px, -50%);
  transform: translate(4px, -50%);
  box-shadow: -4px 0 0 0 var(--color-border-dark-2);
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  background-color: var(--color-light-1);
  outline: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

.slick-prev-none button.slick-prev {
  display: none !important;
}

.slick-dots-none .slick-dots {
  display: none !important;
}

ul.slider-navigation-layout1 {
  padding: 0;
  margin: 0;
  list-style: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
  width: 100%;
}

ul.slider-navigation-layout1 li {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  font-size: 24px;
  border: 1px solid;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

ul.slider-navigation-layout1 li.prev:hover {
  -webkit-transform: translateX(-3px);
  -ms-transform: translateX(-3px);
  transform: translateX(-3px);
}

ul.slider-navigation-layout1 li.next:hover {
  -webkit-transform: translateX(3px);
  -ms-transform: translateX(3px);
  transform: translateX(3px);
}

ul.slider-navigation-layout1.nav-size-extra-large li {
  height: 70px;
  width: 70px;
}

ul.slider-navigation-layout1.nav-size-extra-large li.prev:hover {
  -webkit-transform: translateX(-5px);
  -ms-transform: translateX(-5px);
  transform: translateX(-5px);
}

ul.slider-navigation-layout1.nav-size-extra-large li.next:hover {
  -webkit-transform: translateX(5px);
  -ms-transform: translateX(5px);
  transform: translateX(5px);
}

ul.slider-navigation-layout1.nav-size-large li {
  height: 60px;
  width: 60px;
}

ul.slider-navigation-layout1.nav-size-large li.prev:hover {
  -webkit-transform: translateX(-4px);
  -ms-transform: translateX(-4px);
  transform: translateX(-4px);
}

ul.slider-navigation-layout1.nav-size-large li.next:hover {
  -webkit-transform: translateX(4px);
  -ms-transform: translateX(4px);
  transform: translateX(4px);
}

ul.slider-navigation-layout1.nav-size-medium li {
  height: 40px;
  width: 40px;
}

ul.slider-navigation-layout1.nav-size-medium li.prev:hover {
  -webkit-transform: translateX(-2px);
  -ms-transform: translateX(-2px);
  transform: translateX(-2px);
}

ul.slider-navigation-layout1.nav-size-medium li.next:hover {
  -webkit-transform: translateX(2px);
  -ms-transform: translateX(2px);
  transform: translateX(2px);
}

ul.slider-navigation-layout1.nav-size-small li {
  height: 30px;
  width: 30px;
}

ul.slider-navigation-layout1.nav-size-small li.prev:hover {
  -webkit-transform: translateX(-1px);
  -ms-transform: translateX(-1px);
  transform: translateX(-1px);
}

ul.slider-navigation-layout1.nav-size-small li.next:hover {
  -webkit-transform: translateX(1px);
  -ms-transform: translateX(1px);
  transform: translateX(1px);
}

ul.slider-navigation-layout1.position-layout1 {
  position: absolute;
  bottom: 30px;
  right: 30px;
  width: auto;
}

ul.slider-navigation-layout1.position-layout2 {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}

ul.slider-navigation-layout1.position-layout2 li {
  position: absolute;
}

ul.slider-navigation-layout1.position-layout2 li.prev {
  left: -20px;
}

ul.slider-navigation-layout1.position-layout2 li.next {
  right: -20px;
}

@media (max-width: 767px) {
  ul.slider-navigation-layout1.position-layout2 li.prev {
    left: -12px;
  }

  ul.slider-navigation-layout1.position-layout2 li.next {
    right: -12px;
  }
}

ul.slider-navigation-layout1.color-light-1 li {
  background-color: var(--color-light-1);
  border-color: var(--color-border-dark-1);
  color: var(--color-dark-1);
}

ul.slider-navigation-layout1.color-light-1 li.prev:hover {
  box-shadow: 3px 0 0 0 var(--color-border-dark-1);
}

ul.slider-navigation-layout1.color-light-1 li.next:hover {
  box-shadow: -3px 0 0 0 var(--color-border-dark-1);
}

ul.slider-navigation-layout1.color-light-1.nav-size-extra-large li.prev:hover {
  box-shadow: 5px 0 0 0 var(--color-border-dark-1);
}

ul.slider-navigation-layout1.color-light-1.nav-size-extra-large li.next:hover {
  box-shadow: -5px 0 0 0 var(--color-border-dark-1);
}

ul.slider-navigation-layout1.color-light-1.nav-size-large li.prev:hover {
  box-shadow: 4px 0 0 0 var(--color-border-dark-1);
}

ul.slider-navigation-layout1.color-light-1.nav-size-large li.next:hover {
  box-shadow: -4px 0 0 0 var(--color-border-dark-1);
}

ul.slider-navigation-layout1.color-light-1.nav-size-medium li.prev:hover {
  box-shadow: 2px 0 0 0 var(--color-border-dark-1);
}

ul.slider-navigation-layout1.color-light-1.nav-size-medium li.next:hover {
  box-shadow: -2px 0 0 0 var(--color-border-dark-1);
}

ul.slider-navigation-layout1.color-light-1.nav-size-small li.prev:hover {
  box-shadow: 1px 0 0 0 var(--color-border-dark-1);
}

ul.slider-navigation-layout1.color-light-1.nav-size-small li.next:hover {
  box-shadow: -1px 0 0 0 var(--color-border-dark-1);
}

ul.slider-navigation-layout1.color-light-1-fixed li {
  background-color: var(--color-light-1-fixed);
  border-color: var(--color-border-dark-1-fixed);
  color: var(--color-dark-1-fixed);
}

ul.slider-navigation-layout1.color-light-1-fixed li.prev:hover {
  box-shadow: 3px 0 0 0 var(--color-border-dark-1-fixed);
}

ul.slider-navigation-layout1.color-light-1-fixed li.next:hover {
  box-shadow: -3px 0 0 0 var(--color-border-dark-1-fixed);
}

ul.slider-navigation-layout1.color-light-1-fixed.nav-size-extra-large li.prev:hover {
  box-shadow: 5px 0 0 0 var(--color-border-dark-1-fixed);
}

ul.slider-navigation-layout1.color-light-1-fixed.nav-size-extra-large li.next:hover {
  box-shadow: -5px 0 0 0 var(--color-border-dark-1-fixed);
}

ul.slider-navigation-layout1.color-light-1-fixed.nav-size-large li.prev:hover {
  box-shadow: 4px 0 0 0 var(--color-border-dark-1-fixed);
}

ul.slider-navigation-layout1.color-light-1-fixed.nav-size-large li.next:hover {
  box-shadow: -4px 0 0 0 var(--color-border-dark-1-fixed);
}

ul.slider-navigation-layout1.color-light-1-fixed.nav-size-medium li.prev:hover {
  box-shadow: 2px 0 0 0 var(--color-border-dark-1-fixed);
}

ul.slider-navigation-layout1.color-light-1-fixed.nav-size-medium li.next:hover {
  box-shadow: -2px 0 0 0 var(--color-border-dark-1-fixed);
}

ul.slider-navigation-layout1.color-light-1-fixed.nav-size-small li.prev:hover {
  box-shadow: 1px 0 0 0 var(--color-border-dark-1-fixed);
}

ul.slider-navigation-layout1.color-light-1-fixed.nav-size-small li.next:hover {
  box-shadow: -1px 0 0 0 var(--color-border-dark-1-fixed);
}

ul.slider-navigation-layout1.item-gap-5 {
  -webkit-column-gap: 5px;
  -moz-column-gap: 5px;
  column-gap: 5px;
}

.initially-none .single-slide {
  display: none;
}

.initially-none.slick-initialized.slick-slider .single-slide {
  display: block;
}

/*-------------------------
    Global
-------------------------*/
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: 999999999;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  display: none;
}

.preloader .loader-wrap {
  width: 172px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
}

.preloader .loader-wrap .single-box {
  position: relative;
  width: 35px;
}

.preloader .loader-wrap .single-box .circle-holder {
  position: relative;
  overflow: hidden;
  height: 150px;
}

.preloader .loader-wrap .single-box .circle-holder:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  -webkit-animation: circle_up_down 1.2s infinite linear;
  animation: circle_up_down 1.2s infinite linear;
  z-index: 2;
}

.preloader .loader-wrap .single-box:nth-child(1) .circle-holder:before {
  background-color: var(--color-scandal);
}

.preloader .loader-wrap .single-box:nth-child(2) .circle-holder:before {
  background-color: var(--color-mimosa);
}

.preloader .loader-wrap .single-box:nth-child(3) .circle-holder:before {
  background-color: var(--color-selago);
}

.preloader .loader-wrap .single-box:nth-child(4) .circle-holder:before {
  background-color: var(--color-old-lace);
}

.preloader .loader-wrap .single-box .shadow-holder {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  border-radius: 50%;
  background: #aaaaaa;
  opacity: 1;
  -webkit-animation: shadow_scale 1.2s infinite linear;
  animation: shadow_scale 1.2s infinite linear;
  z-index: 1;
}

.preloader .loader-wrap .single-box:nth-child(1) .circle-holder:before,
.preloader .loader-wrap .single-box:nth-child(1) .shadow-holder {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.preloader .loader-wrap .single-box:nth-child(2) .circle-holder:before,
.preloader .loader-wrap .single-box:nth-child(2) .shadow-holder {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.preloader .loader-wrap .single-box:nth-child(3) .circle-holder:before,
.preloader .loader-wrap .single-box:nth-child(3) .shadow-holder {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.preloader .loader-wrap .single-box:nth-child(4) .circle-holder:before,
.preloader .loader-wrap .single-box:nth-child(4) .shadow-holder {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

@-webkit-keyframes circle_up_down {
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }

  40% {
    -webkit-transform: translateY(-90px);
    transform: translateY(-90px);
    width: 20px;
    height: 20px;
  }

  50% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    width: 20px;
    height: 20px;
  }

  65% {
    -webkit-transform: translateY(-90px);
    transform: translateY(-90px);
    width: 20px;
    height: 20px;
  }

  75% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    width: 20px;
    height: 20px;
  }

  100% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    width: 35px;
    height: 10px;
  }
}

@keyframes circle_up_down {
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }

  40% {
    -webkit-transform: translateY(-90px);
    transform: translateY(-90px);
    width: 20px;
    height: 20px;
  }

  50% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    width: 20px;
    height: 20px;
  }

  65% {
    -webkit-transform: translateY(-90px);
    transform: translateY(-90px);
    width: 20px;
    height: 20px;
  }

  75% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    width: 20px;
    height: 20px;
  }

  100% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    width: 35px;
    height: 10px;
  }
}

@-webkit-keyframes shadow_scale {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1;
  }

  50% {
    -webkit-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0.1;
  }

  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1;
  }
}

@keyframes shadow_scale {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1;
  }

  50% {
    -webkit-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0.1;
  }

  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 1;
  }
}

/*=======================================================================
3. Template Styles
=========================================================================*/
/*-------------------------
    404
-------------------------*/
.error-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
}

.error-box {
  text-align: center;
}

.error-box .description {
  max-width: 600px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

/*-------------------------
    About
-------------------------*/
.about-box-layout-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

.about-box-layout-1 .figure-holder {
  height: 100%;
  position: relative;
}

.about-box-layout-1 .figure-holder img {
  height: 100%;
  object-fit: cover;
}

.about-box-layout-1 .content-holder {
  margin-top: 20px;
}

@media (max-width: 991px) {
  .about-box-layout-1 .content-holder {
    margin-top: 0;
  }
}

.about-box-layout-1 .list-style-1 {
  padding: 0;
  margin: 0 0 25px 0;
  list-style: none;
}

.about-box-layout-1 .list-style-1 li {
  max-width: 785px;
  width: 100%;
  padding-left: 25px;
  margin-bottom: 10px;
  position: relative;
}

.about-box-layout-1 .list-style-1 li:last-child {
  margin-bottom: 0;
}

.about-box-layout-1 .list-style-1 li:before {
  content: "\ed8e";
  font-family: "icomoon" !important;
  position: absolute;
  left: 0;
  top: 4px;
  line-height: 1;
  color: var(--color-body);
  font-weight: 500;
}

/*-------------------------
    Author
-------------------------*/
.author-box-layout1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 30px;
}

@media (max-width: 575px) {
  .author-box-layout1 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
}

.author-box-layout1 .figure-holder {
  max-width: 180px;
  width: 100%;
  border: 1px solid;
  border-color: var(--color-border-dark-1-fixed);
  border-radius: 50%;
}

.author-box-layout1 .figure-holder img {
  border-radius: 50%;
}

.author-box-layout1 .content-holder .title {
  color: var(--color-dark-1);
  margin-bottom: 2px;
}

.author-box-layout1 .content-holder .designation {
  color: var(--color-dark-1);
  margin-bottom: 7px;
  position: relative;
  display: inline-block;
  z-index: 1;
  font-size: 18px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.author-box-layout1 .content-holder .designation:before {
  content: "";
  height: 20px;
  width: 100%;
  position: absolute;
  z-index: -1;
  bottom: -1px;
  left: 0;
  background-color: var(--color-heading-1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.author-box-layout1 .content-holder .description {
  color: var(--color-body);
  max-width: 820px;
  width: 100%;
  margin-bottom: 10px;
}

@media (max-width: 575px) {
  .author-box-layout1 .content-holder .axil-social {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

/*-------------------------
    Category
-------------------------*/
.category-wrap-layout-1 .category-slider-1 {
  padding: 0 20px;
}

@media (max-width: 991px) {
  .category-wrap-layout-1 .heading-nav-wrap {
    padding: 0 30px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-column-gap: 10px;
    -moz-column-gap: 10px;
    column-gap: 10px;
  }

  .category-wrap-layout-1 .heading-nav-wrap .section-heading {
    margin-bottom: 0;
  }

  .category-wrap-layout-1 .heading-nav-wrap .slider-navigation-layout1 {
    width: auto;
  }
}

.category-box-layout1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 16px;
  -moz-column-gap: 16px;
  column-gap: 16px;
  padding: 0 10px;
}

.category-box-layout1 .figure-holder {
  max-width: 100px;
  width: 100%;
}

.category-box-layout1 .figure-holder .link-wrap {
  border-radius: 50%;
}

.category-box-layout1 .figure-holder .link-wrap img {
  border-radius: 50%;
}

.category-box-layout1 .content-holder .entry-title {
  margin-bottom: 0;
}

.category-box-layout2 .figure-holder {
  position: relative;
}

.category-box-layout2 .entry-category {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 12px;
  margin-bottom: 0;
}

.category-box-layout2 .entry-category ul {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/*-------------------------
    Contact
-------------------------*/
.contact-layout-1 .contact-info-box {
  padding: 50px 30px;
  text-align: center;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

@media (max-width: 991px) {
  .contact-layout-1 .contact-info-box {
    padding: 40px 20px;
  }
}

@media (max-width: 767px) {
  .contact-layout-1 .contact-info-box {
    padding: 30px 15px;
  }
}

.contact-layout-1 .contact-info-box .box-icon {
  line-height: 1;
  font-size: 40px;
  margin-bottom: 13px;
  color: var(--color-dark-1-fixed);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.contact-layout-1 .contact-info-box .contact-way {
  color: var(--color-dark-1-fixed);
  font-size: 20px;
  font-weight: 700;
  font-family: var(--font-secondary);
  display: inline-block;
  margin-bottom: 2px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.contact-layout-1 .contact-info-box .contact-text {
  font-size: 17px;
  margin-bottom: 30px;
  color: var(--color-body-fixed);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.contact-form-wrap {
  margin-bottom: 50px;
  padding: 60px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

@media (max-width: 991px) {
  .contact-form-wrap {
    padding: 40px;
  }
}

@media (max-width: 767px) {
  .contact-form-wrap {
    padding: 30px;
  }
}

@media (max-width: 575px) {
  .contact-form-wrap {
    margin-bottom: 42px;
  }
}

.contact-form-wrap form textarea,
.contact-form-wrap form input {
  font-size: 18px;
  padding: 15px 0;
  margin-bottom: 20px;
  color: var(--color-body);
  border-radius: 0;
  border: 0;
  background-color: transparent;
  border-bottom: 1px solid;
  border-color: var(--color-border-dark-2);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.contact-form-wrap form textarea:focus,
.contact-form-wrap form input:focus {
  color: var(--color-body);
  box-shadow: none;
  outline: none;
  border-color: var(--color-border-dark-1);
  background-color: transparent;
}

.contact-form-wrap form input.styled-checkbox {
  padding: initial;
  border: 1px solid;
  border-color: var(--color-border-dark-1);
}

.contact-form-wrap form .select-type {
  margin-bottom: 20px;
}

.contact-form-wrap form .select-type .select-title {
  color: var(--color-dark-1);
  font-size: 18px;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 20px;
}

.contact-form-wrap form .select-type .select-list {
  list-style: none;
  padding: 0;
  margin: 0;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

@media (max-width: 575px) {
  .contact-form-wrap form .select-type .select-list {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
}

.contact-form-wrap form .select-type .select-list label {
  color: var(--color-body);
  font-size: 18px;
  padding-left: 5px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.contact-form-wrap form .select-type .select-list label,
.contact-form-wrap form .select-type .select-list input {
  cursor: pointer;
}

.contact-form-wrap form input::-webkit-input-placeholder,
.contact-form-wrap form textarea::-webkit-input-placeholder {
  color: var(--color-meta);
  font-size: 18px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.contact-form-wrap form input:-ms-input-placeholder,
.contact-form-wrap form textarea:-ms-input-placeholder {
  color: var(--color-meta);
  font-size: 18px;
  -ms-transition: var(--transition);
  transition: var(--transition);
}

.contact-form-wrap form input:-moz-placeholder,
.contact-form-wrap form textarea:-moz-placeholder {
  color: var(--color-meta);
  font-size: 18px;
  -moz-transition: var(--transition);
  transition: var(--transition);
}

.map-wrap {
  margin-bottom: 50px;
}

@media (max-width: 575px) {
  .map-wrap {
    margin-bottom: 42px;
  }
}

.map-wrap .map-box {
  text-align: center;
}

.faq-box-layout1 {
  padding: 60px 60px 70px;
  border-radius: 8px;
}

@media (max-width: 991px) {
  .faq-box-layout1 {
    padding: 50px 50px 60px;
  }
}

@media (max-width: 767px) {
  .faq-box-layout1 {
    padding: 40px 40px 50px;
  }
}

@media (max-width: 575px) {
  .faq-box-layout1 {
    padding: 30px 30px 40px;
  }
}

.faq-box-layout1 .single-item {
  margin-bottom: 15px;
  padding-bottom: 25px;
  border-radius: 0;
  background-color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.faq-box-layout1 .single-item .item-nav {
  padding: 0;
  border: none;
  margin-bottom: 0;
  background-color: transparent;
}

.faq-box-layout1 .single-item .item-nav a {
  font-family: var(--font-secondary);
  font-size: 22px;
  font-weight: 600;
  border-radius: 0;
  color: var(--color-dark-1);
  width: 100%;
  display: block;
  padding: 10px 50px 0 0;
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

@media (max-width: 767px) {
  .faq-box-layout1 .single-item .item-nav a {
    font-size: 20px;
  }
}

@media (max-width: 575px) {
  .faq-box-layout1 .single-item .item-nav a {
    font-size: 18px;
  }
}

.faq-box-layout1 .single-item .item-nav a:after {
  position: absolute;
  content: "\edf1";
  top: 18px;
  right: 3px;
  font-size: 10px;
  z-index: 5;
  font-family: "icomoon";
  color: var(--color-dark-1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.faq-box-layout1 .single-item .item-nav a:before {
  border-radius: 50%;
  content: "";
  height: 16px;
  width: 16px;
  border: 1px solid;
  border-color: var(--color-dark-1);
  position: absolute;
  top: 17px;
  right: 0;
  z-index: 1;
}

.faq-box-layout1 .single-item .item-nav a.collapsed {
  padding: 10px 50px 0 0;
  color: var(--color-dark-1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.faq-box-layout1 .single-item .item-nav a.collapsed:after {
  content: "\ee09";
}

.faq-box-layout1 .single-item .item-content-wrap .item-content {
  padding: 15px 0 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.faq-box-layout1 .single-item .item-content-wrap .item-content p {
  margin-bottom: 0;
  padding-right: 50px;
}

.faq-box-layout1 .single-item:last-child {
  border-bottom: 0 !important;
  margin-bottom: 0;
  padding-bottom: 0;
}

/*-------------------------
    Hero
-------------------------*/
.hero-wrap-layout1 {
  padding-top: 70px;
  padding-bottom: 90px;
}

@media (max-width: 1199px) {
  .hero-wrap-layout1 {
    padding-top: 60px;
    padding-bottom: 75px;
  }
}

@media (max-width: 991px) {
  .hero-wrap-layout1 {
    padding-top: 50px;
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .hero-wrap-layout1 {
    padding-top: 40px;
    padding-bottom: 45px;
  }
}

.hero-box-layout1 {
  text-align: center;
}

.hero-box-layout1 .title {
  max-width: 1000px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.hero-box-layout1 .title span {
  font-weight: 400;
}

.hero-box-layout1 .search-form {
  margin-top: 20px;
  height: 60px;
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-border-dark-1);
  background-color: var(--color-light-1);
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  box-shadow: 5px 5px 0px 0px var(--color-border-dark-1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

@media (max-width: 991px) {
  .hero-box-layout1 .search-form {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .hero-box-layout1 .search-form {
    display: inline-block;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    width: 100%;
  }

  .hero-box-layout1 .search-form:hover {
    box-shadow: none;
    -webkit-transform: translateX(0) translateY(0);
    -ms-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
}

.hero-box-layout1 .search-form .email-input {
  background-color: transparent;
  border: 0;
  height: 52px;
  width: 484px;
  padding: 2px 25px;
  border-radius: 8px 0 0 8px;
  color: var(--color-dark-1);
}

.hero-box-layout1 .search-form .email-input:focus {
  outline: none;
}

@media (max-width: 767px) {
  .hero-box-layout1 .search-form .email-input {
    width: 100%;
    height: 45px;
    border-radius: 8px;
    background-color: var(--color-light-1-fixed);
    margin-bottom: 5px;
    border: 1px solid;
    border-color: var(--color-border-dark-1-fixed);
  }
}

.hero-box-layout1 .search-form .axil-btn {
  font-size: 18px;
  padding: 5px 23px;
  height: 58px;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-color: var(--color-border-dark-1);
  background-color: var(--color-btn-bg);
  border-radius: 0 8px 8px 0;
}

@media (max-width: 767px) {
  .hero-box-layout1 .search-form .axil-btn {
    width: 100%;
    height: 45px;
    border-radius: 8px;
    border: 1px solid;
    border-color: var(--color-border-dark-1);
  }
}

.hero-box-layout1 .search-form input::-webkit-input-placeholder {
  color: var(--color-body);
}

.hero-box-layout1 .search-form input:-ms-input-placeholder {
  color: var(--color-body);
}

.hero-box-layout1 .search-form input:-moz-placeholder {
  color: var(--color-body);
}

/*-------------------------
    Newsletter
-------------------------*/
.newsletter-box-layout1 {
  text-align: center;
  padding: 61px 30px;
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {
  .newsletter-box-layout1 {
    padding: 40px 20px;
  }
}

.newsletter-box-layout1 .entry-title {
  max-width: 600px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6px;
}

.newsletter-box-layout1 .entry-description {
  max-width: 400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.newsletter-box-layout1 .newsletter-form {
  margin-top: 10px;
  display: inline-block;
  background-color: var(--color-light-1-fixed);
}

@media (max-width: 767px) {
  .newsletter-box-layout1 .newsletter-form {
    background-color: transparent;
    border: 0;
    box-shadow: none;
  }

  .newsletter-box-layout1 .newsletter-form:hover {
    box-shadow: none !important;
    -webkit-transform: translateX(0) translateY(0);
    -ms-transform: translateX(0) translateY(0);
    transform: translateX(0) translateY(0);
  }
}

.newsletter-box-layout1 .newsletter-form .email-input {
  background-color: transparent;
  border: 0;
  height: 40px;
  width: 372px;
  padding: 2px 15px;
  border-radius: 8px 0 0 8px;
  color: var(--color-dark-1-fixed);
}

.newsletter-box-layout1 .newsletter-form .email-input:focus {
  outline: none;
}

@media (max-width: 767px) {
  .newsletter-box-layout1 .newsletter-form .email-input {
    width: 100%;
    height: 45px;
    border-radius: 8px;
    background-color: var(--color-light-1-fixed);
    margin-bottom: 5px;
    border: 1px solid;
    border-color: var(--color-border-dark-1-fixed);
  }
}

.newsletter-box-layout1 .newsletter-form .axil-btn {
  height: 41px;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  background-color: var(--color-btn-bg);
  border-color: var(--color-border-dark-1);
  border-radius: 0 8px 8px 0;
}

@media (max-width: 767px) {
  .newsletter-box-layout1 .newsletter-form .axil-btn {
    border-radius: 8px;
    border: 1px solid;
    border-color: var(--color-border-dark-1);
  }

  .newsletter-box-layout1 .newsletter-form .axil-btn:hover {
    box-shadow: 3px 3px 0px 0px var(--color-border-dark-1-fixed);
    -webkit-transform: translateX(-3px) translateY(-3px);
    -ms-transform: translateX(-3px) translateY(-3px);
    transform: translateX(-3px) translateY(-3px);
  }
}

.newsletter-box-layout1 .newsletter-form .btn-border2 {
  border-color: var(--color-border-dark-2);
}

@media (max-width: 767px) {
  .newsletter-box-layout1 .newsletter-form .btn-border2 {
    border-color: var(--color-border-dark-2);
  }

  .newsletter-box-layout1 .newsletter-form .btn-border2:hover {
    box-shadow: 3px 3px 0px 0px var(--color-border-dark-2-fixed);
  }
}

.newsletter-box-layout1 .newsletter-form input::-webkit-input-placeholder {
  color: var(--color-dark-1-fixed);
}

.newsletter-box-layout1 .newsletter-form input:-ms-input-placeholder {
  color: var(--color-dark-1-fixed);
}

.newsletter-box-layout1 .newsletter-form input:-moz-placeholder {
  color: var(--color-dark-1-fixed);
}

.newsletter-box-layout1 .elements-wrap {
  list-style: none;
  padding: 0;
  margin: 0;
}

@media (max-width: 767px) {
  .newsletter-box-layout1 .elements-wrap {
    display: none;
  }
}

.newsletter-box-layout1 .elements-wrap li {
  position: absolute;
  z-index: 2;
}

.newsletter-box-layout1 .elements-wrap li:nth-child(1n) {
  top: inherit;
  bottom: 33px;
  left: 27px;
  right: inherit;
}

.newsletter-box-layout1 .elements-wrap li:nth-child(2n) {
  top: 0;
  bottom: inherit;
  left: inherit;
  right: 0;
}

/*-------------------------
    Post
-------------------------*/
.videoPlayer-1 {
  width: 100%;
  height: 100%;
  position: relative;
  background-size: cover;
  top: 0;
  left: 0;
  z-index: 0;
}

@media (max-width: 991px) {
  .videoPlayer-1 {
    height: 400px;
  }
}

@media (max-width: 767px) {
  .videoPlayer-1 {
    height: 300px;
  }
}

.video-post-wrap .video-box {
  margin-bottom: -8px;
  max-width: 100%;
  width: 100%;
}

.audio-post-wrap {
  background-color: var(--color-black-fixed);
  overflow: hidden;
}

.audio-post-wrap iframe {
  width: 100%;
}

.post-box-layout1 {
  height: 100%;
  position: relative;
  z-index: 1;
}

.post-box-layout1 .content-holder {
  padding: 40px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (max-width: 767px) {
  .post-box-layout1 .content-holder {
    padding: 30px;
  }
}

.post-box-layout2 {
  padding-bottom: 65px;
  margin-bottom: 6px;
}

.post-box-layout2 .figure-holder {
  margin-bottom: 15px;
}

.post-box-layout2 .content-holder .entry-title {
  margin-bottom: 7px;
}

.post-box-layout2 .content-holder .entry-description {
  margin-bottom: 13px;
}

.post-box-layout3 {
  position: relative;
}

.post-box-layout3 .content-holder {
  padding: 20px 20px 12px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
}

.post-box-layout3 .content-holder .entry-title {
  margin-bottom: 5px;
}

.post-box-layout4 .figure-holder {
  margin-bottom: 15px;
}

.post-box-layout4 .content-holder .entry-title {
  margin-bottom: 12px;
}

.post-box-layout4 .content-holder .entry-description {
  margin-bottom: 23px;
}

.post-box-layout4 .content-holder .entry-meta {
  margin-bottom: 20px;
}

.post-box-layout5 {
  position: relative;
  z-index: 1;
}

.post-box-layout5 .content-holder {
  padding: 40px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
}

.post-box-layout6 .figure-holder {
  margin-bottom: 15px;
}

.post-box-layout6 .content-holder .entry-title {
  margin-bottom: 12px;
}

.post-box-layout7 .figure-holder {
  margin-bottom: 15px;
}

.post-box-layout7 .content-holder {
  border-bottom: 1px solid;
  border-color: var(--color-border-dark-1);
  margin-bottom: 18px;
  padding-bottom: 25px;
}

.post-box-layout7 .content-holder:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.post-box-layout7 .content-holder .entry-title {
  margin-bottom: 13px;
}

.post-box-layout8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
}

.post-box-layout8 .figure-holder {
  position: relative;
  max-width: 140px;
  width: 100%;
}

.post-box-layout8 .content-holder {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.post-box-layout8 .content-holder .entry-title {
  margin-bottom: 10px;
}

.post-box-layout9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
  height: 100%;
}

.post-box-layout9 .figure-holder {
  position: relative;
  max-width: 285px;
  width: 100%;
  height: 100%;
}

.post-box-layout9 .figure-holder .link-wrap {
  height: 100%;
}

.post-box-layout9 .figure-holder img {
  /* height: 100%; */
  /* object-fit: cover; */
}

.post-box-layout9 .content-holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.post-box-layout9 .content-holder .entry-title {
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .post-box-layout9 {
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
  }
}

@media (max-width: 575px) {
  .post-box-layout9 {
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .post-box-layout9 .figure-holder {
    max-width: inherit;
    height: auto;
    margin-bottom: 20px;
  }
}

.post-box-layout10 {
  position: relative;
  z-index: 1;
}

.post-box-layout10 .content-holder {
  padding: 40px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
}

.post-box-layout10 .content-holder .entry-title {
  margin-bottom: 0;
}

.post-box-layout11 .figure-holder {
  margin-bottom: 15px;
}

.post-box-layout11 .content-holder .entry-title {
  margin-bottom: 0;
}

.post-box-layout12 .single-item:first-child {
  margin-bottom: 25px;
}

.post-box-layout12 .single-item:first-child .figure-holder {
  margin-bottom: 15px;
}

.post-box-layout12 .single-item:first-child .content-holder .entry-title {
  margin-bottom: 8px;
}

.post-box-layout12 .single-item:first-child .content-holder .entry-description {
  margin-bottom: 10px;
}

.post-box-layout12 .single-item:not(:first-child) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 16px;
  border-color: var(--color-border-dark-1-fixed);
  border-bottom: 1px solid;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .post-box-layout12 .single-item:not(:first-child) {
    display: block;
  }
}

.post-box-layout12 .single-item:not(:first-child) .figure-holder {
  position: relative;
  max-width: 160px;
  width: 100%;
}

.post-box-layout12 .single-item:not(:first-child) .figure-holder .figure-overlay:before {
  display: none;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .post-box-layout12 .single-item:not(:first-child) .figure-holder {
    max-width: inherit;
    margin-bottom: 15px;
  }
}

@media (max-width: 480px) {
  .post-box-layout12 .single-item:not(:first-child) .figure-holder {
    max-width: 110px;
  }
}

.post-box-layout12 .single-item:not(:first-child) .content-holder {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.post-box-layout12 .single-item:not(:first-child) .content-holder .entry-title {
  font-size: 16px;
  margin-bottom: 0;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .post-box-layout12 .single-item:not(:first-child) .content-holder .entry-category {
    margin-bottom: 5px;
  }
}

@media (max-width: 480px) {
  .post-box-layout12 .single-item:not(:first-child) .content-holder .entry-category {
    margin-bottom: 5px;
  }
}

.post-box-layout12 .single-item:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.post-box-layout12 .single-item.no-divider:not(:first-child) {
  border-bottom: 0;
  padding-bottom: 0;
}

.post-box-layout13 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 30px;
  height: 100%;
}

@media (max-width: 1199px) {
  .post-box-layout13 {
    gap: 20px;
  }
}

@media (max-width: 991px) {
  .post-box-layout13 {
    display: block;
    height: auto;
  }
}

.post-box-layout13 .figure-holder {
  position: relative;
  max-width: 410px;
  width: 100%;
  height: 100%;
}

@media (max-width: 991px) {
  .post-box-layout13 .figure-holder {
    height: auto;
    margin-bottom: 20px;
    max-width: inherit;
  }
}

.post-box-layout13 .figure-holder .link-wrap {
  height: 100%;
}

.post-box-layout13 .figure-holder .link-wrap img {
  height: 100%;
  object-fit: cover;
}

.post-box-layout13 .content-holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
  padding-right: 30px;
}

.post-box-layout13 .content-holder .entry-category {
  margin-bottom: 16px;
}

.post-box-layout13 .content-holder .entry-title {
  margin-bottom: 17px;
}

.post-box-layout13 .content-holder .entry-description {
  margin-bottom: 18px;
}

.post-box-layout13 .content-holder .entry-meta {
  margin-bottom: 30px;
}

@media (max-width: 1199px) {
  .post-box-layout13 .content-holder {
    padding-right: 0;
  }
}

.post-box-layout14 {
  position: relative;
  z-index: 1;
}

.post-box-layout14 .content-holder {
  padding: 30px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (max-width: 575px) {
  .post-box-layout14 .content-holder {
    padding: 20px;
  }
}

.post-box-layout14 .content-holder .entry-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.post-box-layout15 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 16px;
  -moz-column-gap: 16px;
  column-gap: 16px;
}

.post-box-layout15 .figure-holder {
  width: 100px;
  border-radius: 50%;
}

.post-box-layout15 .figure-holder img {
  border-radius: 50%;
}

.post-box-layout15 .content-holder {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.post-box-layout15 .content-holder .entry-title {
  margin-bottom: 10px;
}

.post-box-layout16 {
  position: relative;
  z-index: 1;
}

.post-box-layout16 .content-holder {
  padding: 24px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
}

.post-box-layout16 .content-holder .entry-title {
  margin-bottom: 0;
}

@media (max-width: 575px) {
  .post-box-layout16 .content-holder {
    padding: 20px;
  }
}

.post-box-layout17 {
  min-height: 600px;
  position: relative;
  z-index: 1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.post-box-layout17 .content-holder {
  background-color: var(--color-light-1);
  max-width: 700px;
  position: absolute;
  bottom: 40px;
  -webkit-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  transform: translateY(-100px);
  opacity: 0;
  visibility: hidden;
  left: 40px;
  right: 40px;
  padding: 30px;
  border-radius: 8px;
  -webkit-transition: var(--transition-slider);
  transition: var(--transition-slider);
  -webkit-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

@media (max-width: 575px) {
  .post-box-layout17 .content-holder {
    left: 15px;
    right: 15px;
    bottom: 15px;
    padding: 20px;
  }
}

.post-box-layout17.slick-active .content-holder {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.post-box-layout18 .video-post-wrap {
  margin-bottom: 16px;
}

.post-box-layout18 .video-post-wrap .video-box {
  height: 248px;
  object-fit: cover;
}

@media (max-width: 1299px) {
  .post-box-layout18 .video-post-wrap .video-box {
    height: 221px;
  }
}

@media (max-width: 1199px) {
  .post-box-layout18 .video-post-wrap .video-box {
    height: 292px;
  }
}

@media (max-width: 991px) {
  .post-box-layout18 .video-post-wrap .video-box {
    height: 207px;
  }
}

@media (max-width: 767px) {
  .post-box-layout18 .video-post-wrap .video-box {
    height: 337px;
  }
}

@media (max-width: 575px) {
  .post-box-layout18 .video-post-wrap .video-box {
    height: 61vw;
  }
}

.post-box-layout18 .audio-post-wrap {
  margin-bottom: 16px;
}

.post-box-layout18 .audio-post-wrap iframe {
  margin-bottom: -24px;
  height: 264px;
}

@media (max-width: 1299px) {
  .post-box-layout18 .audio-post-wrap iframe {
    height: 237px;
  }
}

@media (max-width: 1199px) {
  .post-box-layout18 .audio-post-wrap iframe {
    height: 309px;
  }
}

@media (max-width: 991px) {
  .post-box-layout18 .audio-post-wrap iframe {
    height: 223px;
  }
}

@media (max-width: 767px) {
  .post-box-layout18 .audio-post-wrap iframe {
    height: 353px;
  }
}

@media (max-width: 575px) {
  .post-box-layout18 .audio-post-wrap iframe {
    height: 62vw;
  }
}

.post-box-layout18 .figure-holder {
  margin-bottom: 16px;
}

.post-box-layout18 .content-holder .entry-title {
  margin-bottom: 10px;
}

.post-box-layout19 {
  margin-bottom: 80px;
}

@media (max-width: 767px) {
  .post-box-layout19 {
    margin-bottom: 50px;
  }
}

.post-box-layout19:last-child {
  margin-bottom: 0;
}

.post-box-layout19 .video-post-wrap {
  margin-bottom: 17px;
}

.post-box-layout19 .audio-post-wrap {
  margin-bottom: 17px;
}

.post-box-layout19 .audio-post-wrap iframe {
  margin-bottom: -8px;
  height: 436px;
}

@media (max-width: 1299px) {
  .post-box-layout19 .audio-post-wrap iframe {
    height: 394px;
  }
}

@media (max-width: 1199px) {
  .post-box-layout19 .audio-post-wrap iframe {
    height: 373px;
  }
}

@media (max-width: 991px) {
  .post-box-layout19 .audio-post-wrap iframe {
    height: 308px;
  }
}

@media (max-width: 767px) {
  .post-box-layout19 .audio-post-wrap iframe {
    height: 226px;
  }
}

@media (max-width: 575px) {
  .post-box-layout19 .audio-post-wrap iframe {
    height: 50.3vw;
  }
}

.post-box-layout19 .entry-meta {
  margin-bottom: 12px;
}

.post-box-layout19 .entry-title {
  margin-bottom: 28px;
}

.post-box-layout19 .figure-holder {
  margin-bottom: 17px;
}

.post-box-layout19 .entry-description {
  margin-bottom: 23px;
}

.post-box-layout19 .post-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid;
  border-color: var(--color-border-dark-1);
  padding-top: 15px;
  margin-top: 35px;
}

.post-box-layout20 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
  height: 100%;
}

@media (max-width: 767px) {
  .post-box-layout20 {
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
  }
}

.post-box-layout20 .audio-post-wrap iframe {
  margin-bottom: -24px;
  height: 100%;
}

.post-box-layout20 .video-post-wrap,
.post-box-layout20 .audio-post-wrap,
.post-box-layout20 .figure-holder {
  height: 100%;
  position: relative;
  max-width: 360px;
  width: 100%;
}

@media (max-width: 1199px) {

  .post-box-layout20 .video-post-wrap,
  .post-box-layout20 .audio-post-wrap,
  .post-box-layout20 .figure-holder {
    max-width: 300px;
  }
}

@media (max-width: 991px) {

  .post-box-layout20 .video-post-wrap,
  .post-box-layout20 .audio-post-wrap,
  .post-box-layout20 .figure-holder {
    max-width: 250px;
  }
}

@media (max-width: 767px) {

  .post-box-layout20 .video-post-wrap,
  .post-box-layout20 .audio-post-wrap,
  .post-box-layout20 .figure-holder {
    max-width: 200px;
  }
}

.post-box-layout20 .video-post-wrap .link-wrap,
.post-box-layout20 .audio-post-wrap .link-wrap,
.post-box-layout20 .figure-holder .link-wrap {
  height: 100%;
}

.post-box-layout20 .video-post-wrap .video-box,
.post-box-layout20 .video-post-wrap img,
.post-box-layout20 .audio-post-wrap .video-box,
.post-box-layout20 .audio-post-wrap img,
.post-box-layout20 .figure-holder .video-box,
.post-box-layout20 .figure-holder img {
  height: 100%;
  object-fit: cover;
}

@media (max-width: 575px) {
  .post-box-layout20 {
    display: block;
  }

  .post-box-layout20 .figure-holder {
    max-width: inherit;
    height: auto;
    margin-bottom: 20px;
  }

  .post-box-layout20 .audio-post-wrap {
    max-width: inherit;
    height: 86vw;
    margin-bottom: 20px;
  }

  .post-box-layout20 .video-post-wrap {
    max-width: inherit;
    height: 85.6vw;
    margin-bottom: 20px;
  }
}

.post-box-layout20 .content-holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.post-box-layout20 .content-holder .entry-title {
  margin-bottom: 10px;
}

.post-box-layout20 .content-holder .entry-description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.post-box-layout21 {
  position: relative;
  z-index: 1;
}

.post-box-layout21 .content-holder {
  padding: 30px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
}

.post-box-layout21 .content-holder .entry-title {
  margin-bottom: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.post-box-layout22 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
  height: 100%;
}

.post-box-layout22 .figure-holder {
  position: relative;
  max-width: 150px;
  width: 100%;
  height: 100%;
}

.post-box-layout22 .figure-holder .link-wrap {
  height: 100%;
}

.post-box-layout22 .figure-holder img {
  height: 100%;
  object-fit: cover;
}

.post-box-layout22 .content-holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.post-box-layout22 .content-holder .entry-title {
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  .post-box-layout22 {
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    column-gap: 15px;
  }
}

@media (max-width: 575px) {
  .post-box-layout22 {
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .post-box-layout22 .figure-holder {
    max-width: inherit;
    height: auto;
    margin-bottom: 15px;
  }
}

.post-box-layout23 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
}

.post-box-layout23 .figure-holder {
  max-width: 120px;
  width: 100%;
  position: relative;
  z-index: 1;
}

.post-box-layout23 .content-holder .entry-title {
  margin-bottom: 10px;
}

.multi-posts-layout1 .nav {
  padding-bottom: 5px;
  border-bottom: 1px solid;
  border-color: var(--color-border-dark-1-fixed);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.multi-posts-layout1 .nav .nav-item .nav-link {
  color: var(--color-dark-1-fixed);
  padding: 10px 0 8px;
  border: 0;
  font-family: var(--font-secondary);
  font-size: 22px;
  font-weight: 600;
  position: relative;
  z-index: 1;
}

.multi-posts-layout1 .nav .nav-item .nav-link:before {
  content: "";
  height: 12px;
  width: 0;
  position: absolute;
  z-index: -1;
  bottom: 10px;
  left: 0;
  background-color: var(--color-aquamarine);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.multi-posts-layout1 .nav .nav-item .nav-link.active {
  background-color: transparent;
}

.multi-posts-layout1 .nav .nav-item .nav-link.active:before {
  width: 100%;
}

.multi-posts-layout2 .nav {
  border: 0;
}

.multi-posts-layout2 .nav .nav-item {
  counter-increment: css-counter 1;
  margin-bottom: 50px;
}

@media (max-width: 991px) {
  .multi-posts-layout2 .nav .nav-item {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .multi-posts-layout2 .nav .nav-item {
    margin-bottom: 25px;
  }
}

.multi-posts-layout2 .nav .nav-item:last-child {
  margin-bottom: 0;
}

.multi-posts-layout2 .nav .nav-item .nav-link {
  padding: 0 0 0 58px;
  opacity: 0.6;
  border: 0;
  color: var(--color-meta-dark-1);
  font-size: 18px;
  font-weight: 600;
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.multi-posts-layout2 .nav .nav-item .nav-link:before {
  opacity: 0.6;
  content: counter(css-counter);
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  line-height: 1;
  font-size: 65px;
  color: var(--color-meta-dark-1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

@media (max-width: 1199px) {
  .multi-posts-layout2 .nav .nav-item .nav-link:before {
    font-size: 50px;
  }
}

@media (max-width: 991px) {
  .multi-posts-layout2 .nav .nav-item .nav-link:before {
    font-size: 40px;
  }
}

.multi-posts-layout2 .nav .nav-item .nav-link span {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (max-width: 1199px) {
  .multi-posts-layout2 .nav .nav-item .nav-link {
    padding: 0 0 0 42px;
  }
}

.multi-posts-layout2 .nav .nav-item .nav-link.active {
  opacity: 1;
  color: var(--color-dark-1);
  background-color: transparent;
}

.multi-posts-layout2 .nav .nav-item .nav-link.active:before {
  opacity: 1;
  color: var(--color-dark-1);
}

.multi-posts-layout3 .nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 40px;
  -moz-column-gap: 40px;
  column-gap: 40px;
  border: 0;
  margin-bottom: 30px;
}

.multi-posts-layout3 .nav .nav-item {
  display: inline-block;
  width: calc(50% - 20px);
  border: 0;
}

.multi-posts-layout3 .nav .nav-item .nav-link {
  border: 1px solid;
  border-color: var(--color-border-dark-1-fixed);
  color: var(--color-dark-1-fixed);
  border-radius: 45px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  padding: 5px 20px;
  height: 44px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--color-btn-bg);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.multi-posts-layout3 .nav .nav-item .nav-link.active {
  background-color: var(--color-light-1-fixed);
  -webkit-transform: translateX(-3px) translateY(-3px);
  -ms-transform: translateX(-3px) translateY(-3px);
  transform: translateX(-3px) translateY(-3px);
  box-shadow: 3px 3px 0px 0px var(--color-border-dark-1-fixed);
}

.multi-posts-layout3 .nav .nav-item .nav-link:hover {
  background-color: var(--color-light-1-fixed);
}

.multi-posts-layout3 .tab-content .tab-pane>div {
  margin-bottom: 16px;
}

.multi-posts-layout3 .tab-content .tab-pane>div:last-child {
  margin-bottom: 0;
}

.multi-posts-layout4>div {
  margin-bottom: 30px;
}

.multi-posts-layout4>div:last-child {
  margin-bottom: 0;
}

.multi-posts-layout4 .post-box {
  padding: 16px;
}

.multi-posts-layout5 .post-box:first-child {
  position: relative;
  z-index: 1;
}

.multi-posts-layout5 .post-box:first-child .figure-holder {
  margin-bottom: 20px;
}

.multi-posts-layout5 .post-box:first-child .content-holder {
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  padding: 30px;
}

@media (max-width: 575px) {
  .multi-posts-layout5 .post-box:first-child .content-holder {
    padding: 20px;
  }
}

.multi-posts-layout5 .post-box:not(:first-child) {
  padding: 0 20px;
}

.multi-posts-layout5 .post-box:not(:first-child) .content-holder {
  border-bottom: 1px solid;
  border-color: var(--color-border-dark-2);
  padding-bottom: 22px;
  margin-bottom: 24px;
}

.multi-posts-layout5 .post-box:not(:first-child) .content-holder .entry-title {
  margin-bottom: 18px;
}

.multi-posts-layout5 .post-box:last-child .content-holder {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 20px;
}

/*-------------------------
    Sidebar
-------------------------*/
@media (max-width: 991px) {
  .sidebar-global {
    margin-top: 60px;
  }
}

@media (max-width: 767px) {
  .sidebar-global {
    margin-top: 50px;
  }
}

.sidebar-global .sidebar-widget {
  margin-bottom: 30px;
}

.sidebar-global .sidebar-widget:last-child {
  margin-bottom: 0;
}

.widget-tagcloud.tagcloud-layout1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 16px;
}

.widget-tagcloud.tagcloud-layout1 .tag-cloud-link {
  border: 1px solid;
  border-color: var(--color-border-dark-1);
  border-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 5px;
  -moz-column-gap: 5px;
  column-gap: 5px;
  background-color: var(--color-light-1);
  color: var(--color-dark-1);
  padding: 6px 12px;
  font-size: 14px;
  font-family: var(--font-secondary);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.widget-tagcloud.tagcloud-layout1 .tag-cloud-link .icon-holder {
  line-height: 1;
}

.widget-tagcloud.tagcloud-layout1 .tag-cloud-link:hover {
  color: var(--color-dark-1-fixed);
  background-color: var(--color-atomic-tangerine);
}

.widget-tagcloud.tagcloud-layout2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 16px;
}

.widget-tagcloud.tagcloud-layout2 .tag-cloud-link {
  border: 1px solid;
  border-color: var(--color-border-dark-2);
  border-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 5px;
  -moz-column-gap: 5px;
  column-gap: 5px;
  background-color: var(--color-light-1);
  color: var(--color-dark-1);
  padding: 6px 12px;
  font-size: 14px;
  font-family: var(--font-secondary);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.widget-tagcloud.tagcloud-layout2 .tag-cloud-link .icon-holder {
  line-height: 1;
}

.widget-tagcloud.tagcloud-layout3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 16px;
}

.widget-tagcloud.tagcloud-layout3 .tag-cloud-link {
  border: 1px solid;
  border-color: var(--color-border-dark-1);
  border-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 5px;
  -moz-column-gap: 5px;
  column-gap: 5px;
  background-color: var(--color-light-1);
  color: var(--color-dark-1);
  padding: 6px 12px;
  font-size: 14px;
  font-family: var(--font-secondary);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.widget-tagcloud.tagcloud-layout3 .tag-cloud-link .icon-holder {
  line-height: 1;
}

.widget-tagcloud.tagcloud-layout4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 16px;
}

.widget-tagcloud.tagcloud-layout4 .tag-cloud-link {
  border: 1px solid;
  border-color: var(--color-border-dark-1);
  border-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 5px;
  -moz-column-gap: 5px;
  column-gap: 5px;
  background-color: var(--color-light-1);
  color: var(--color-dark-1);
  padding: 6px 12px;
  font-size: 14px;
  font-family: var(--font-secondary);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.widget-tagcloud.tagcloud-layout4 .tag-cloud-link .icon-holder {
  line-height: 1;
}

.widget-newsletter {
  padding: 50px 40px 40px;
  text-align: center;
  border-radius: 8px;
}

@media (max-width: 1199px) {
  .widget-newsletter {
    padding: 40px 30px 30px;
  }
}

@media (max-width: 767px) {
  .widget-newsletter {
    padding: 30px 20px 20px;
  }
}

.widget-newsletter .title {
  margin-bottom: 10px;
  font-size: 34px;
  font-weight: 900;
}

@media (max-width: 1199px) {
  .widget-newsletter .title {
    font-size: 30px;
  }
}

@media (max-width: 991px) {
  .widget-newsletter .title {
    font-size: 28px;
  }
}

@media (max-width: 767px) {
  .widget-newsletter .title {
    font-size: 26px;
  }
}

@media (max-width: 575px) {
  .widget-newsletter .title {
    font-size: 24px;
  }
}

.widget-newsletter .sub-title {
  font-family: var(--font-secondary);
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 15px;
  position: relative;
  display: inline-block;
  z-index: 1;
}

.widget-newsletter .sub-title:before {
  content: "";
  height: 30px;
  width: 30px;
  border-radius: 45px;
  position: absolute;
  left: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.widget-newsletter .sub-title:after {
  content: "";
  height: 2px;
  width: 82px;
  position: absolute;
  z-index: 1;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -20px;
}

.widget-newsletter .newsletter-form {
  margin-top: 67px;
}

@media (max-width: 1199px) {
  .widget-newsletter .newsletter-form {
    margin-top: 60px;
  }
}

@media (max-width: 991px) {
  .widget-newsletter .newsletter-form {
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .widget-newsletter .newsletter-form {
    margin-top: 40px;
  }
}

.widget-newsletter .newsletter-form .email-input {
  background-color: transparent;
  border: 1px solid;
  width: 100%;
  height: 40px;
  padding: 2px 15px;
  border-radius: 8px;
  margin-bottom: 8px;
}

.widget-newsletter .newsletter-form .email-input:focus {
  outline: none;
}

.widget-newsletter .newsletter-form .axil-btn {
  padding: 6px 18px 3px;
}

.widget-newsletter .newsletter-form .axil-btn i {
  font-weight: 900;
  font-size: 18px;
}

.widget-newsletter:hover .sub-title:before {
  width: calc(100% + 40px);
}

.widget-newsletter.newsletter-layout1 {
  background-color: var(--color-scandal);
}

.widget-newsletter.newsletter-layout1 .title {
  color: var(--color-dark-1-fixed);
}

.widget-newsletter.newsletter-layout1 .sub-title {
  color: var(--color-dark-1-fixed);
}

.widget-newsletter.newsletter-layout1 .sub-title:before {
  background-color: var(--color-screamin-green);
}

.widget-newsletter.newsletter-layout1 .sub-title:after {
  background-color: var(--color-dark-1-fixed);
}

.widget-newsletter.newsletter-layout1 .newsletter-form .email-input {
  border-color: var(--color-border-dark-1-fixed);
  color: var(--color-dark-1-fixed);
}

.widget-newsletter.newsletter-layout1 .newsletter-form input::-webkit-input-placeholder {
  color: var(--color-dark-1-fixed);
}

.widget-newsletter.newsletter-layout1 .newsletter-form input:-ms-input-placeholder {
  color: var(--color-dark-1-fixed);
}

.widget-newsletter.newsletter-layout1 .newsletter-form input:-moz-placeholder {
  color: var(--color-dark-1-fixed);
}

.widget-newsletter.newsletter-layout2 {
  background-color: var(--color-black-fixed);
}

.widget-newsletter.newsletter-layout2 .title {
  color: var(--color-light-1-fixed);
}

.widget-newsletter.newsletter-layout2 .sub-title {
  color: var(--color-light-1-fixed);
}

.widget-newsletter.newsletter-layout2 .sub-title:before {
  background-color: var(--color-screamin-green);
}

.widget-newsletter.newsletter-layout2 .sub-title:after {
  background-color: var(--color-light-1-fixed);
}

.widget-newsletter.newsletter-layout2 .newsletter-form .email-input {
  border-color: var(--color-border-light-1-fixed);
  color: var(--color-light-3-fixed);
}

.widget-newsletter.newsletter-layout2 .newsletter-form input::-webkit-input-placeholder {
  color: var(--color-light-3-fixed);
}

.widget-newsletter.newsletter-layout2 .newsletter-form input:-ms-input-placeholder {
  color: var(--color-light-3-fixed);
}

.widget-newsletter.newsletter-layout2 .newsletter-form input:-moz-placeholder {
  color: var(--color-light-3-fixed);
}

.widget-post.post-layout1 .post-box:first-child {
  margin-bottom: 16px;
  position: relative;
}

.widget-post.post-layout1 .post-box:first-child .content-holder {
  padding: 24px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
}

.widget-post.post-layout1 .post-box:first-child .content-holder .entry-title {
  margin-bottom: 10px;
}

.widget-post.post-layout1 .post-box:not(:first-child) {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid;
  border-color: var(--color-border-dark-1);
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.widget-post.post-layout1 .post-box:not(:first-child) .figure-holder {
  position: relative;
  max-width: 100px;
  width: 100%;
}

.widget-post.post-layout1 .post-box:not(:first-child) .figure-holder .figure-overlay:before {
  display: none;
}

.widget-post.post-layout1 .post-box:not(:first-child) .content-holder {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.widget-post.post-layout1 .post-box:not(:first-child) .content-holder .entry-title {
  font-size: 16px;
  margin-bottom: 5px;
}

.widget-post.post-layout1 .post-box:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.widget-post.post-layout2>div {
  margin-bottom: 16px;
}

.widget-post.post-layout2>div:last-child {
  margin-bottom: 0;
}

.widget-post.post-layout2 .post-box {
  padding: 16px;
  border-radius: 8px;
}

.widget-post.post-layout3 .post-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid;
  border-color: var(--color-border-dark-1);
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.widget-post.post-layout3 .post-box .figure-holder {
  position: relative;
  max-width: 100px;
  width: 100%;
}

.widget-post.post-layout3 .post-box .figure-holder .figure-overlay:before {
  display: none;
}

.widget-post.post-layout3 .post-box .content-holder {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.widget-post.post-layout3 .post-box .content-holder .entry-title {
  margin-bottom: 5px;
}

.widget-post.post-layout3 .post-box:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}

.widget-search.search-layout1 {
  text-align: center;
  background-color: var(--color-scandal);
}

.widget-search.search-layout1 .title {
  margin-bottom: 6px;
  color: var(--color-dark-1-fixed);
}

.widget-search.search-layout1 .description {
  color: var(--color-tundora);
}

.widget-search.search-layout1 form {
  border-radius: 45px;
  border: 1px solid;
  border-color: var(--color-border-dark-1-fixed);
  position: relative;
  width: 100%;
}

.widget-search.search-layout1 .icon-holder {
  background-color: transparent;
  border: none;
  color: var(--color-dark-1-fixed);
  line-height: 1;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.widget-search.search-layout1 input {
  font-weight: 400;
  font-size: 15px;
  color: var(--color-dark-1-fixed);
  background-color: var(--color-light-1-fixed);
  height: 42px;
  padding: 5px 45px;
  border: 0;
  border-radius: 45px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.widget-search.search-layout1 input:focus {
  color: var(--color-dark-1-fixed);
  box-shadow: none;
  border: 0;
  background-color: var(--color-light-1-fixed);
}

.widget-search.search-layout1 input::-webkit-input-placeholder {
  color: var(--color-body-fixed);
  font-weight: 400;
  font-size: 15px;
  font-family: var(--font-primary);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.widget-search.search-layout1 input::-moz-placeholder {
  color: var(--color-body-fixed);
  font-weight: 400;
  font-size: 15px;
  font-family: var(--font-primary);
  -moz-transition: var(--transition);
  transition: var(--transition);
}

.widget-search.search-layout1 input:-moz-placeholder {
  color: var(--color-body-fixed);
  font-weight: 400;
  font-size: 15px;
  font-family: var(--font-primary);
  -moz-transition: var(--transition);
  transition: var(--transition);
}

.widget-search.search-layout1 input:-ms-input-placeholder {
  color: var(--color-body-fixed);
  font-weight: 400;
  font-size: 15px;
  font-family: var(--font-primary);
  -ms-transition: var(--transition);
  transition: var(--transition);
}

.widget-follow.follow-layout1 {
  text-align: center;
  background-color: var(--color-selago);
}

.widget-follow.follow-layout1 .title {
  margin-bottom: 6px;
  color: var(--color-dark-1-fixed);
}

.widget-follow.follow-layout1 .description {
  color: var(--color-tundora);
}

.widget-category.category-layout1 {
  padding: 40px;
}

@media (max-width: 767px) {
  .widget-category.category-layout1 {
    padding: 30px;
  }
}

@media (max-width: 575px) {
  .widget-category.category-layout1 {
    padding: 20px;
  }
}

.widget-category.category-layout1 .category-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.widget-category.category-layout1 .category-list li {
  color: var(--color-dark-1-fixed);
  font-weight: 700;
  margin-bottom: 15px;
  padding-bottom: 12px;
  border-bottom: 1px solid;
  border-color: var(--color-border-dark-1-fixed);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.widget-category.category-layout1 .category-list li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.widget-category.category-layout1 .category-list li a {
  color: var(--color-dark-1-fixed);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.widget-category.category-layout1 .category-list li a:hover {
  color: var(--color-primary);
}

.widget-category.category-layout2 {
  background-color: var(--color-light-1);
  border-radius: 8px;
  padding: 40px;
}

@media (max-width: 767px) {
  .widget-category.category-layout2 {
    padding: 30px;
  }
}

@media (max-width: 575px) {
  .widget-category.category-layout2 {
    padding: 20px;
  }
}

.widget-category.category-layout2 .category-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.widget-category.category-layout2 .category-list li {
  color: var(--color-dark-1);
  font-weight: 700;
  margin-bottom: 15px;
  padding-bottom: 12px;
  border-bottom: 1px solid;
  border-color: var(--color-border-dark-2);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.widget-category.category-layout2 .category-list li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.widget-category.category-layout2 .category-list li a {
  color: var(--color-dark-1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.widget-category.category-layout2 .category-list li a:hover {
  color: var(--color-primary);
}

/*-------------------------
    Single Post
-------------------------*/
.single-blog-content .audio-post-wrap {
  margin-bottom: 30px;
}

.single-blog-content .audio-post-wrap iframe {
  margin-bottom: -9px;
}

.single-blog-content .video-post-wrap {
  margin-bottom: 20px;
}

.single-blog-content .entry-meta {
  margin-bottom: 20px;
}

.single-blog-content .axil-social {
  margin-bottom: 30px;
}

.single-blog-content .list-style-1 {
  padding: 0;
  margin: 0 0 25px 0;
  list-style: none;
}

.single-blog-content .list-style-1 li {
  max-width: 785px;
  width: 100%;
  padding-left: 25px;
  margin-bottom: 10px;
  position: relative;
}

.single-blog-content .list-style-1 li:last-child {
  margin-bottom: 0;
}

.single-blog-content .list-style-1 li:before {
  /* content: "\ed8e"; */
  /* font-family: "icomoon" !important; */
  position: absolute;
  left: 0;
  top: 5px;
  line-height: 1;
  color: var(--color-body);
  font-weight: 500;
  font-size: 12px;
  content: "\F231";
  font-family: var(--bootstrap-icons);
}

.single-blog-content .figure-caption {
  margin-top: 10px;
  color: var(--color-body);
}

.single-blog-content blockquote {
  padding: 80px 30px 30px 30px;
  margin-bottom: 20px;
  position: relative;
  background-color: var(--color-old-lace);
  border-radius: 8px;
}

.single-blog-content blockquote:before {
  /* content: "\ec33"; */
  /* font-family: var(--font-icomoon); */
  position: absolute;
  left: 24px;
  font-weight: 500;
  top: 6px;
  font-size: 70px;
  line-height: 1;
  opacity: 0.3;
  color: var(--color-body-fixed);
  content: "\F6B0";
  font-family: var(--bootstrap-icons);
}

.single-blog-content blockquote:after {
  border-radius: 8px 0 0 8px;
  content: "";
  background-color: var(--color-perano);
  height: 100%;
  width: 5px;
  position: absolute;
  left: 0;
  top: 0;
}

.single-blog-content blockquote p {
  font-family: var(--font-secondary);
  font-size: 18px;
  font-style: italic;
  font-weight: 500;
  margin-bottom: 10px;
  color: var(--color-body-fixed);
}

.single-blog-content blockquote cite {
  position: relative;
  padding-left: 40px;
  font-style: normal;
  font-size: 15px;
  color: var(--color-body-fixed);
}

.single-blog-content blockquote cite:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 35px;
  height: 2px;
  background-color: var(--color-body-fixed);
}

.single-blog-content .tag-share-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 40px;
  gap: 20px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.single-blog-content .tag-share-wrap .tagcloud {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px;
}

.single-blog-content .tag-share-wrap .tagcloud .tag-cloud-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 5px;
  -moz-column-gap: 5px;
  column-gap: 5px;
  line-height: 1;
  border-radius: 5px;
  padding: 7px 12px 4px;
  background-color: transparent;
  border: 1px solid;
  border-color: var(--color-border-dark-1);
  color: var(--color-dark-1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-blog-content .tag-share-wrap .tagcloud .tag-cloud-link .icon-holder {
  line-height: 1;
}

.single-blog-content .tag-share-wrap .tagcloud .tag-cloud-link:hover {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--color-light-1-fixed);
}

.single-blog-content .tag-share-wrap .axil-social {
  margin-bottom: 0;
}

.single-blog-content .author-wrap {
  margin-bottom: 40px;
  padding: 95px 100px 30px;
  border-radius: 8px;
  text-align: center;
  position: relative;
  margin-top: 90px;
}

@media (max-width: 767px) {
  .single-blog-content .author-wrap {
    padding: 95px 30px 30px;
  }
}

@media (max-width: 575px) {
  .single-blog-content .author-wrap {
    padding: 95px 15px 30px;
  }
}

.single-blog-content .author-wrap .author-thumb {
  max-width: 120px;
  width: 100%;
  margin: 0 auto 20px;
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  border: 1px solid;
  border-color: var(--color-border-dark-1);
  border-radius: 50%;
}

.single-blog-content .author-wrap .author-thumb img {
  border-radius: 50%;
}

.single-blog-content .author-wrap .author-content .entry-title {
  margin-bottom: 5px;
  font-size: 20px;
}

.single-blog-content .author-wrap .author-content .author-designation {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  color: var(--color-dark-1-fixed);
}

.single-blog-content .author-wrap .author-content .axil-social {
  margin-bottom: 0;
}

.single-blog-content .author-wrap .author-content .axil-social ul {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.single-blog-content .post-navigation {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 40px;
  padding-bottom: 40px;
  padding-top: 40px;
  border-width: 1px 0;
  border-style: solid;
  border-color: var(--color-border-dark-1);
}

.single-blog-content .post-navigation .post-box {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px;
}

.single-blog-content .post-navigation .post-box .figure-holder {
  max-width: 90px;
  width: 100%;
}

.single-blog-content .post-navigation .post-box .figure-holder .link-wrap {
  border-radius: 50%;
}

.single-blog-content .post-navigation .post-box .figure-holder img {
  border-radius: 50%;
}

@media (max-width: 575px) {
  .single-blog-content .post-navigation .post-box .figure-holder {
    display: none;
  }
}

.single-blog-content .post-navigation .post-box .entry-title {
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.single-blog-content .post-navigation .post-box .text-box {
  font-size: 14px;
  margin-bottom: 5px;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 5px;
  -moz-column-gap: 5px;
  column-gap: 5px;
  color: var(--color-dark-1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-blog-content .post-navigation .post-box .text-box:hover {
  color: var(--color-primary);
}

.single-blog-content .post-navigation .post-box .text-box .icon-holder {
  font-size: 20px;
  line-height: 1;
}

.single-blog-content .post-navigation .post-box.prev-post {
  margin-right: 15px;
  position: relative;
}

.single-blog-content .post-navigation .post-box.prev-post:before {
  content: "";
  height: 50px;
  width: 1px;
  background-color: var(--color-border-dark-1);
  position: absolute;
  right: -15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.single-blog-content .post-navigation .post-box.next-post {
  text-align: right;
  margin-left: 15px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.single-blog-content .leave-comment p {
  font-size: 15px;
}

.single-blog-content .leave-comment .leave-form-box textarea.form-control,
.single-blog-content .leave-comment .leave-form-box input.form-control {
  color: var(--color-dark-1);
  border: 1px solid;
  border-color: var(--color-border-dark-1);
  margin-bottom: 12px;
  padding-left: 20px;
  border-radius: 8px;
  background-color: var(--color-light-2);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-blog-content .leave-comment .leave-form-box textarea.form-control:focus,
.single-blog-content .leave-comment .leave-form-box input.form-control:focus {
  color: var(--color-dark-1);
  box-shadow: none;
  background-color: var(--color-light-2);
  border-color: var(--color-border-dark-1);
}

.single-blog-content .leave-comment .leave-form-box input.form-control {
  height: 50px;
}

@media (max-width: 767px) {
  .single-blog-content .leave-comment .leave-form-box input.form-control {
    margin-bottom: 0;
  }
}

.single-blog-content .leave-comment .leave-form-box textarea.form-control {
  height: 180px;
  padding: 15px 20px;
}

.single-blog-content .leave-comment .leave-form-box input::-webkit-input-placeholder {
  font-size: 15px;
  color: var(--color-meta-dark-1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-blog-content .leave-comment .leave-form-box input:-ms-input-placeholder {
  font-size: 15px;
  color: var(--color-meta-dark-1);
  -ms-transition: var(--transition);
  transition: var(--transition);
}

.single-blog-content .leave-comment .leave-form-box input:-moz-placeholder {
  font-size: 15px;
  color: var(--color-meta-dark-1);
  -moz-transition: var(--transition);
  transition: var(--transition);
}

.single-blog-content .leave-comment .leave-form-box input[type="checkbox"] {
  position: relative;
  background-color: transparent;
  margin: 0;
  font: inherit;
  color: var(--color-border-2);
  width: 14px;
  height: 14px;
  border: 1.5px solid var(--color-border-2);
  border-radius: 1px;
  -webkit-transform: translateY(2px);
  -ms-transform: translateY(2px);
  transform: translateY(2px);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-blog-content .leave-comment .leave-form-box .show-message-label {
  color: var(--color-meta-dark-1);
  font-size: 15px;
  font-weight: 400;
  margin-left: 2px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-blog-content .post-comment ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.single-blog-content .post-comment ul li .each-comment {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.single-blog-content .post-comment ul li .each-comment .comment-figure {
  width: 100px;
  display: table;
}

.single-blog-content .post-comment ul li .each-comment .comment-figure img {
  border-radius: 50%;
}

.single-blog-content .post-comment ul li .each-comment .comment-content {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.single-blog-content .post-comment ul li .each-comment .comment-content .comment-title {
  margin-bottom: 5px;
  font-size: 18px;
}

.single-blog-content .post-comment ul li .each-comment .comment-content .comment-meta {
  margin-bottom: 12px;
  font-size: 15px;
}

.single-blog-content .post-comment ul li .each-comment .comment-content .comment-meta .post-date {
  color: var(--color-meta-dark-1);
}

.single-blog-content .post-comment ul li .each-comment .comment-content .item-btn {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--color-light-2);
  color: var(--color-dark-1);
  padding: 3px 12px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.single-blog-content .post-comment ul li .each-comment .comment-content .item-btn:hover {
  background-color: var(--color-primary);
  color: var(--color-light-1-fixed);
}

@media (max-width: 575px) {
  .single-blog-content .post-comment ul li .each-comment .comment-content .item-btn {
    position: inherit;
  }
}

.single-blog-content .post-comment ul li .each-comment .comment-content .comment-comment {
  color: var(--color-dark-1);
}

.single-blog-content .post-comment ul li .children {
  padding-left: 50px;
}

.single-blog-content.content-layout2 {
  position: relative;
  margin-bottom: 40px;
}

.single-blog-content.content-layout2 .banner-figure-holder {
  position: relative;
  height: 400px;
}

.single-blog-content.content-layout2 .banner-figure-holder:before {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: -webkit-linear-gradient(bottom,
      #020112 0%,
      rgba(52, 51, 59, 0.55) 40.78%,
      rgba(217, 217, 217, 0) 100%);
  background: linear-gradient(360deg,
      #020112 0%,
      rgba(52, 51, 59, 0.55) 40.78%,
      rgba(217, 217, 217, 0) 100%);
}

.single-blog-content.content-layout2 .banner-figure-holder img {
  height: 100%;
  object-fit: cover;
  border-radius: 0;
}

@media (min-width: 768px) {
  .single-blog-content.content-layout2 .banner-figure-holder {
    height: 450px;
  }
}

@media (min-width: 992px) {
  .single-blog-content.content-layout2 .banner-figure-holder {
    height: 500px;
  }
}

@media (min-width: 1200px) {
  .single-blog-content.content-layout2 .banner-figure-holder {
    height: 600px;
  }
}

@media (min-width: 1300px) {
  .single-blog-content.content-layout2 .banner-figure-holder {
    height: 700px;
  }
}

.single-blog-content.content-layout2 .mask-content {
  z-index: 1;
  position: absolute;
  bottom: 50px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.singleBlogContent .banner-img {
  border-radius: 6px;
  margin-bottom: 16px;
}

.singleBlogContent .blog-image {
  border-radius: 6px;
}

.singleBlogContent .heading-main {
  font-size: var(--h4-large);
  line-height: 1.42;
  margin: 0 0 14px 0;
}

.singleBlogContent .heading-secondary {
  font-size: var(--h2-small);
  line-height: 1.42;
  margin: 0 0 14px 0;
}

.singleBlogContent p.paragraph-content {
  margin: 0 0 14px 0;
}

.singleBlogContent .row .col-4 img {
  height: 250px;
  object-fit: cover;
  border-radius: 4px;
  margin-bottom: 24px;
}

p.paragraph-content br {
  display: none;
}

@media (min-width: 576px) {
  .single-blog-content.content-layout2 .mask-content {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .single-blog-content.content-layout2 .mask-content {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .single-blog-content.content-layout2 .mask-content {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .single-blog-content.content-layout2 .mask-content {
    max-width: 1140px;
  }
}

@media (min-width: 1300px) {
  .single-blog-content.content-layout2 .mask-content {
    max-width: 1254px;
  }
}

.single-blog-content.content-layout2 .mask-content .entry-meta {
  margin-bottom: 25px;
}

.single-blog-content.content-layout2 .mask-content .axil-social {
  margin-bottom: 0;
}

.single-blog-content.content-layout3 {
  margin-bottom: 40px;
}

.single-blog-content.content-layout5 {
  padding: 60px 0;
}

@media (max-width: 767px) {
  .single-blog-content.content-layout5 {
    padding: 50px 0;
  }
}

.single-blog-content.content-layout5 .mask-content {
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 576px) {
  .single-blog-content.content-layout5 .mask-content {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .single-blog-content.content-layout5 .mask-content {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .single-blog-content.content-layout5 .mask-content {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .single-blog-content.content-layout5 .mask-content {
    max-width: 1140px;
  }
}

@media (min-width: 1300px) {
  .single-blog-content.content-layout5 .mask-content {
    max-width: 1254px;
  }
}

.single-blog-content.content-layout5 .mask-content .entry-meta {
  margin-bottom: 25px;
}

.single-blog-content.content-layout5 .mask-content .axil-social {
  margin-bottom: 0;
}

/*-------------------------
    Team
-------------------------*/
.team-wrap-layout1 .border-bottom-dark {
  padding-bottom: 60px;
  border-bottom: 1px solid;
  border-color: var(--color-border-dark-1);
}

@media (max-width: 767px) {
  .team-wrap-layout1 .border-bottom-dark {
    padding-bottom: 50px;
  }
}

.team-box-layout1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 16px;
}

.team-box-layout1 .figure-holder {
  border-radius: 50%;
  max-width: 140px;
  width: 100%;
}

.team-box-layout1 .figure-holder .link-wrap {
  border-radius: 50%;
}

.team-box-layout1 .figure-holder img {
  border-radius: 50%;
}

.team-box-layout1 .content-holder {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.team-box-layout1 .content-holder .entry-title {
  margin-bottom: 8px;
}

.team-box-layout1 .content-holder .entry-title a {
  color: var(--color-dark-1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.team-box-layout1 .content-holder .entry-title a:hover {
  color: var(--color-primary);
}

.team-box-layout1 .content-holder .skill-box .skill-for {
  margin-bottom: 3px;
  font-size: 14px;
}

.team-box-layout1 .content-holder .skill-box .skill-on {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 5px;
  -moz-column-gap: 5px;
  column-gap: 5px;
}

.team-layout-2 .figure-holder {
  margin-bottom: 15px;
  border: 1px solid;
  border-radius: 8px;
  border-color: var(--color-border-dark-1);
}

.team-layout-2 .figure-holder img {
  border-radius: 6px;
}

.team-layout-2 .content-holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.team-layout-2 .content-holder .entry-title {
  margin-bottom: 5px;
}

.team-layout-2 .content-holder .entry-title a {
  color: var(--color-dark-1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.team-layout-2 .content-holder .entry-title a:hover {
  color: var(--color-primary);
}

.team-layout-2 .content-holder .entry-designation {
  margin-bottom: 10px;
  color: var(--color-body);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

/*=======================================================================
4.Header Styles 
=========================================================================*/
/*-------------------------
    Header
-------------------------*/
.offcanvas {
  max-width: inherit;
  color: inherit;
  visibility: inherit;
  background-color: inherit;
  background-clip: inherit;
  outline: inherit;
  -webkit-transition: inherit;
  transition: inherit;
  bottom: inherit;
}

@media (min-width: 992px) {
  header.header nav.template-main-menu>ul.menu>li.menu-item>a {
    position: relative;
    color: var(--color-menu-fixed);
  }

  header.header nav.template-main-menu>ul.menu>li.menu-item>a:before {
    content: "";
    background-color: var(--color-dark-1-fixed);
    height: 4px;
    position: absolute;
    bottom: 0;
    border-radius: 45px 45px 0 0;
  }

  header.header nav.template-main-menu>ul.menu>li.menu-item>a:hover {
    color: var(--color-primary);
  }

  header.header nav.template-main-menu>ul.menu>li.menu-item>a.is-active {
    color: var(--color-primary);
  }

  header.header nav.template-main-menu>ul.menu>li.menu-item>a.is-active:before {
    width: calc(100% - 50px);
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  header.header nav.template-main-menu>ul.menu>li.menu-item.is-active>a {
    color: var(--color-primary);
  }

  header.header nav.template-main-menu>ul.menu>li.menu-item.is-active>a:before {
    width: calc(100% - 50px);
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  header.header nav.template-main-menu>ul.menu>li.menu-item ul.sub-menu {
    border-radius: 0 0 8px 8px;
    background-color: var(--color-light-1);
    box-shadow: 0px 10px 15px 0px rgba(var(--color-black-rgb), 0.1);
  }

  header.header nav.template-main-menu>ul.menu>li.menu-item ul.sub-menu li.menu-item a {
    position: relative;
    color: var(--color-sub-menu);
  }

  header.header nav.template-main-menu>ul.menu>li.menu-item ul.sub-menu li.menu-item a:before {
    content: "";
    height: 1px;
    width: 0;
    background-color: var(--color-primary);
    position: absolute;
    left: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: var(--transition);
    transition: var(--transition);
  }

  header.header nav.template-main-menu>ul.menu>li.menu-item ul.sub-menu li.menu-item a:hover {
    color: var(--color-primary);
    padding-left: 42px;
  }

  header.header nav.template-main-menu>ul.menu>li.menu-item ul.sub-menu li.menu-item a:hover:before {
    width: 15px;
  }

  header.header nav.template-main-menu>ul.menu>li.menu-item .sub-menu .menu-item>a.is-active {
    color: var(--color-primary);
    padding-left: 42px;
  }

  header.header nav.template-main-menu>ul.menu>li.menu-item .sub-menu .menu-item>a.is-active:before {
    width: 15px;
  }

  header.header nav.template-main-menu>ul.menu>li.menu-item .sub-menu .menu-item.menu-item-has-children.is-active>a {
    color: var(--color-primary);
    padding-left: 42px;
  }

  header.header nav.template-main-menu>ul.menu>li.menu-item .sub-menu .menu-item.menu-item-has-children.is-active>a:before {
    width: 15px;
  }

  header.header nav.template-main-menu>ul.menu>li.menu-item.mega-menu-desktop.menu-item-has-children .mega-menu-wrap .mega-menu-bg {
    border-radius: 0 0 8px 8px;
    background-color: var(--color-light-1);
    box-shadow: 0px 10px 15px 0px rgba(var(--color-black-rgb), 0.1);
  }

  .offcanvas {
    position: inherit;
    z-index: inherit;
    display: inherit;
    -webkit-box-orient: inherit;
    -webkit-box-direction: inherit;
    -webkit-flex-direction: inherit;
    -ms-flex-direction: inherit;
    flex-direction: inherit;
  }
}

header.header {
  width: 100%;
  position: relative;
  z-index: 100;
}

@media (max-width: 991px) {
  header.header .navbar-wrap {
    padding: 10px 0;
  }
}

header.header .navbar-wrap .desktop-logo {
  margin-top: 2px;
}

header.header nav.template-main-menu>ul.menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style: none;
}

@media (max-width: 991px) {
  header.header nav.template-main-menu>ul.menu {
    overflow-y: auto;
  }
}

header.header nav.template-main-menu>ul.menu>li.menu-item {
  position: relative;
}

header.header nav.template-main-menu>ul.menu>li.menu-item>a {
  font-size: var(--font-size-menu);
  font-weight: var(--font-weight-menu);
  display: block;
}

header.header nav.template-main-menu>ul.menu>li.menu-item ul.sub-menu {
  padding: 0;
  margin: 0;
  list-style: none;
  z-index: 2;
}

@media (min-width: 992px) {
  header.header nav.template-main-menu>ul.menu>li.menu-item ul.sub-menu {
    padding: 30px 0;
    min-width: 272px;
    -webkit-transition: var(--transition-menu);
    transition: var(--transition-menu);
  }
}

header.header nav.template-main-menu>ul.menu>li.menu-item ul.sub-menu li.menu-item a {
  font-size: var(--font-size-sub-menu);
  font-weight: var(--font-weight-sub-menu);
  padding: 5px 35px;
}

header.header nav.template-main-menu a {
  display: block;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

header.header nav.template-main-menu a:focus {
  outline: none;
}

header.header nav.template-main-menu>ul.menu>li.menu-item>ul.sub-menu {
  margin: 0;
  list-style: none;
}

@media (min-width: 992px) {
  header.header nav.template-main-menu>ul.menu>li.menu-item>ul.sub-menu {
    top: 100%;
    left: 0;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 0 0 0;
    -ms-transform-origin: 0 0 0;
    transform-origin: 0 0 0;
  }
}

header.header nav.template-main-menu>ul.menu>li.menu-item>ul.sub-menu>li.menu-item {
  margin-bottom: 5px;
}

header.header nav.template-main-menu>ul.menu>li.menu-item>ul.sub-menu>li.menu-item:last-child {
  margin-bottom: 0;
}

header.header nav.template-main-menu>ul.menu>li.menu-item>ul.sub-menu>li.menu-item.second-lavel {
  position: relative;
  z-index: 1;
}

@media (min-width: 992px) {
  header.header nav.template-main-menu>ul.menu>li.menu-item>ul.sub-menu>li.menu-item.second-lavel>a:after {
    font-style: normal;
    font-size: 14px;
    position: absolute;
    z-index: 1;
    font-weight: 600;
    top: 7px;
    right: 30px;
    /* content: "\ed6a"; */
    /* font-family: var(--font-icomoon); */
    content: "\F285";
    font-family: bootstrap-icons !important;
  }
}

@media (min-width: 992px) {
  header.header nav.template-main-menu>ul.menu>li.menu-item>ul.sub-menu>li.menu-item.second-lavel>ul.sub-menu {
    border-radius: 8px;
    left: 100%;
    top: 0;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 0 0 0;
    -ms-transform-origin: 0 0 0;
    transform-origin: 0 0 0;
  }
}

header.header nav.template-main-menu>ul.menu>li.menu-item>ul.sub-menu>li.menu-item.second-lavel>ul.sub-menu>li.menu-item {
  margin-bottom: 5px;
}

header.header nav.template-main-menu>ul.menu>li.menu-item>ul.sub-menu>li.menu-item.second-lavel>ul.sub-menu>li.menu-item:last-child {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  header.header nav.template-main-menu>ul.menu>li.menu-item.menu-item-has-children>a {
    position: relative;
  }

  header.header nav.template-main-menu>ul.menu>li.menu-item.menu-item-has-children>a:after {
    /* content: "\ed62"; */
    /* font-family: var(--font-icomoon); */
    position: absolute;
    right: 4px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-weight: 600;
    font-size: 12px;
    content: "\F282";
    font-family: bootstrap-icons !important;
  }

  header.header nav.template-main-menu>ul.menu>li.menu-item.menu-item-has-children:hover ul.sub-menu {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
  }

  header.header nav.template-main-menu>ul.menu>li.menu-item.menu-item-has-children:hover ul.sub-menu li.menu-item-has-children.second-lavel:hover ul.sub-menu {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
  }
}

header.header nav.template-main-menu>ul.menu>li.menu-item.mega-menu-desktop.menu-item-has-children {
  position: inherit;
}

@media (min-width: 992px) {
  header.header nav.template-main-menu>ul.menu>li.menu-item.mega-menu-desktop.menu-item-has-children .mega-menu-wrap {
    left: 0;
    right: 0;
    top: 100%;
    position: absolute;
    z-index: 2;
    visibility: hidden;
    opacity: 0;
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 0 0 0;
    -ms-transform-origin: 0 0 0;
    transform-origin: 0 0 0;
    -webkit-transition: var(--transition-menu);
    transition: var(--transition-menu);
  }
}

header.header nav.template-main-menu>ul.menu>li.menu-item.mega-menu-desktop.menu-item-has-children .mega-menu-wrap .mega-menu-bg .sub-menu {
  box-shadow: none;
}

@media (min-width: 992px) {
  header.header nav.template-main-menu>ul.menu>li.menu-item.mega-menu-desktop.menu-item-has-children:hover .mega-menu-wrap {
    opacity: 1;
    visibility: visible;
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
  }
}

header.header1 .navbar-wrap {
  width: 100%;
  background-color: var(--color-mimosa);
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: var(--color-border-dark-1);
}

@media (min-width: 1200px) {
  header.header1 .navbar-wrap nav.template-main-menu ul.menu>li.menu-item>a {
    padding: 27px 25px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  header.header1 .navbar-wrap nav.template-main-menu ul.menu>li.menu-item>a {
    padding: 27px 20px;
  }
}

header.header1 .navbar-wrap .my_switcher ul li button {
  color: var(--color-dark-1-fixed);
}

header.header1.sticky-on.sticky .navbar-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  box-shadow: 0 0 30px 0 rgba(var(--color-black-rgb-fixed), 0.1);
}

header.header2 .topbar-global {
  padding-left: 100px;
  padding-right: 100px;
}

@media (max-width: 1600px) {
  header.header2 .topbar-global {
    padding-left: 0;
    padding-right: 0;
  }
}

header.header2 .navbar-wrap {
  padding-left: 100px;
  padding-right: 100px;
  width: 100%;
  background-color: var(--color-selago-smooth);
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: var(--color-border-dark-1);
}

@media (max-width: 1600px) {
  header.header2 .navbar-wrap {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1200px) {
  header.header2 .navbar-wrap nav.template-main-menu ul.menu>li.menu-item>a {
    padding: 27px 25px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  header.header2 .navbar-wrap nav.template-main-menu ul.menu>li.menu-item>a {
    padding: 27px 20px;
  }
}

header.header2 .navbar-wrap .my_switcher ul li button {
  color: var(--color-dark-1-fixed);
}

header.header2.sticky-on.sticky .navbar-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  box-shadow: 0 0 30px 0 rgba(var(--color-black-rgb-fixed), 0.1);
}

header.header3 .navbar-wrap {
  width: 100%;
  background-color: var(--color-old-lace);
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: var(--color-border-dark-1);
}

@media (min-width: 1200px) {
  header.header3 .navbar-wrap nav.template-main-menu ul.menu>li.menu-item>a {
    padding: 27px 25px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  header.header3 .navbar-wrap nav.template-main-menu ul.menu>li.menu-item>a {
    padding: 27px 20px;
  }
}

header.header3 .navbar-wrap .my_switcher ul li button {
  color: var(--color-dark-1-fixed);
}

header.header3.sticky-on.sticky .navbar-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  box-shadow: 0 0 30px 0 rgba(var(--color-black-rgb-fixed), 0.1);
}

/*-------------------------
    Mobile Menu
-------------------------*/
@media (min-width: 992px) {
  .mobile-menu-wrap {
    visibility: visible !important;
  }
}

@media (max-width: 991px) {
  .mobile-menu-btn .btn-wrap {
    background-color: transparent;
    border: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0;
    -webkit-transition: var(--transition);
    transition: var(--transition);
  }

  .mobile-menu-btn .btn-wrap span {
    display: block;
    height: 2px;
    width: 20px;
    background-color: var(--color-dark-1-fixed);
    margin-bottom: 4px;
    border-radius: 10px;
    -webkit-transition: var(--transition);
    transition: var(--transition);
  }

  .mobile-menu-btn .btn-wrap span:first-child {
    width: 20px;
    margin-right: 0;
  }

  .mobile-menu-btn .btn-wrap span:last-child {
    margin-bottom: 0;
    width: 20px;
    margin-right: 0;
  }

  .mobile-menu-btn .btn-wrap:focus {
    box-shadow: none;
  }

  .mobile-menu-btn .btn-wrap:hover span:last-child {
    width: 10px;
    margin-right: 10px;
  }

  .mobile-menu-btn .btn-wrap:hover span:first-child {
    width: 10px;
    margin-right: -10px;
  }

  .menuCloseMask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0);
  }

  header.header .mobile-menu-wrap {
    padding-bottom: 140px;
    position: fixed;
    z-index: 2000;
    top: 0;
    left: -260px;
    height: 100vh;
    max-width: 260px;
    width: 100%;
    background-color: var(--color-light-1);
    -webkit-transition: all 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
    transition: all 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }

  header.header .mobile-menu-wrap.show {
    left: 0;
  }

  header.header .mobile-menu-wrap .mobile-logo-wrap {
    padding: 30px 15px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 10px;
    position: relative;
  }

  header.header .mobile-menu-wrap .mobile-logo-wrap .logo-holder {
    /* text-align: center; */
  }

  header.header .mobile-menu-wrap .mobile-logo-wrap .mobile-close {
    color: var(--color-dark-1);
    padding: 0;
    font-size: 22px;
    position: absolute;
    right: 8px;
    line-height: 1;
    top: 8px;
    background-color: transparent;
    border: 0;
    -webkit-transition: var(--transition);
    transition: var(--transition);
  }

  header.header .mobile-menu-wrap .mobile-logo-wrap .mobile-close:focus {
    box-shadow: none;
  }

  header.header .mobile-menu-wrap .mobile-logo-wrap .mobile-close:hover {
    color: var(--color-primary);
  }

  header.header .mobile-menu-wrap nav.template-main-menu {
    /* margin-top: 20px; */
    height: 100%;
    width: 100%;
  }

  header.header .mobile-menu-wrap nav.template-main-menu>ul.menu {
    height: 100%;
    width: 100%;
    display: block;
  }

  header.header .mobile-menu-wrap nav.template-main-menu>ul.menu>li {
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid;
    border-color: var(--color-light-3);
  }

  header.header .mobile-menu-wrap nav.template-main-menu>ul.menu>li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }

  header.header .mobile-menu-wrap nav.template-main-menu>ul.menu>li.is-active>a {
    color: var(--color-primary);
  }

  header.header .mobile-menu-wrap nav.template-main-menu>ul.menu>li a.is-active {
    color: var(--color-primary);
  }

  header.header .mobile-menu-wrap nav.template-main-menu>ul.menu>li.menu-item:first-child>a {
    padding: 5px 15px;
  }

  header.header .mobile-menu-wrap nav.template-main-menu>ul.menu>li.menu-item>a {
    padding: 5px 15px;
  }

  header.header .mobile-menu-wrap nav.template-main-menu ul.menu li.menu-item-has-children {
    position: relative;
  }

  header.header .mobile-menu-wrap nav.template-main-menu ul.menu li.menu-item-has-children:before {
    position: absolute;
    top: 8px;
    right: 10px;
    font-size: 12px;
    z-index: -1;
    /* content: "\ed6a"; */
    /* font-family: var(--font-icomoon); */
    font-weight: 600;
    content: "\F285";
    font-family: bootstrap-icons !important;
  }

  header.header .mobile-menu-wrap nav.template-main-menu ul.menu li.menu-item-has-children ul.sub-menu {
    display: none;
  }

  header.header .mobile-menu-wrap nav.template-main-menu ul.menu li.menu-item-has-children ul.sub-menu li a.is-active {
    color: var(--color-primary);
  }

  header.header .mobile-menu-wrap nav.template-main-menu ul.menu li.menu-item-has-children ul.sub-menu li.is-active>a {
    color: var(--color-primary);
  }

  header.header nav.template-main-menu>ul.menu>li.menu-item>a {
    color: var(--color-menu);
  }

  header.header nav.template-main-menu>ul.menu>li.menu-item>a:hover {
    color: var(--color-primary);
  }

  header.header nav.template-main-menu>ul.menu>li.menu-item ul.sub-menu {
    background-color: var(--color-light-1);
  }

  header.header nav.template-main-menu>ul.menu>li.menu-item ul.sub-menu li.menu-item a {
    color: var(--color-sub-menu);
  }

  header.header nav.template-main-menu>ul.menu>li.menu-item ul.sub-menu li.menu-item a:hover {
    color: var(--color-primary);
  }

  header.header nav.template-main-menu>ul.menu>li.menu-item ul.sub-menu li.menu-item.second-lavel ul.sub-menu li.menu-item a {
    padding: 5px 5px 5px 65px;
  }
}

.headerSocial {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 0px 12px;

    li {
      margin: 0;
      padding: 0;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        font-size: 18px;
        color: var(--color-menu-fixed);
      }
    }
  }
}

/*-------------------------
    Topbar
-------------------------*/
.topbar-global {
  padding: 10px 0;
  min-height: 50px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.topbar-global.color-dark-1-fixed {
  background-color: var(--color-woodsmoke);
}

.topbar-global.color-dark-1-fixed .news-feed-label {
  color: var(--color-light-1-fixed);
}

.topbar-global.color-dark-1-fixed .news-feed-slider .single-slide .link-wrap {
  color: var(--color-light-1-fixed);
}

.topbar-global.color-dark-1-fixed .news-feed-slider .single-slide .link-wrap:hover {
  color: var(--color-primary);
}

.topbar-global.color-dark-1-fixed .current-date {
  color: var(--color-light-1-fixed);
}

.topbar-global.color-dark-1-fixed .current-date:before {
  background-color: var(--color-border-light-1-fixed);
}

.topbar-global.color-dark-1-fixed .my_switcher ul li button {
  color: var(--color-light-1-fixed);
}

.topbar-global.color-dark-1-fixed .notification-wrap .dropdown .dropdown-toggle .icon-holder {
  color: var(--color-light-1-fixed);
}

.topbar-global.color-dark-1-fixed .notification-wrap .has-notification:before {
  border-color: var(--color-light-1-fixed);
}

.topbar-global.color-light-1-fixed {
  background-color: var(--color-light-1-fixed);
}

.topbar-global.color-light-1-fixed .news-feed-slider .single-slide .link-wrap {
  color: var(--color-dark-1-fixed);
}

.topbar-global.color-light-1-fixed .news-feed-slider .single-slide .link-wrap:hover {
  color: var(--color-primary);
}

.topbar-global.color-light-1-fixed .current-date {
  color: var(--color-dark-1-fixed);
}

.topbar-global.color-light-1-fixed .current-date:before {
  background-color: var(--color-border-dark-1-fixed);
}

.topbar-global.color-light-1-fixed .my_switcher ul li button {
  color: var(--color-dark-1-fixed);
}

.topbar-global.color-light-1-fixed .notification-wrap .dropdown .dropdown-toggle .icon-holder {
  color: var(--color-dark-1-fixed);
}

.topbar-global.color-light-1-fixed .notification-wrap .has-notification:before {
  border-color: var(--color-dark-1-fixed);
}

.social-layout-1 {
  display: flex;
  flex-direction: column;
  gap: 16px 0px;
}

.social-layout-1 label {
  color: var(--color-light-1-fixed);
  position: relative;
  font-size: 14px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.social-layout-1 label:before {
  position: absolute;
  height: 20px;
  width: 1px;
  content: "";
  background-color: var(--color-border-light-1-fixed);
  right: -18px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.social-layout-1 ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 5px;
  -moz-column-gap: 5px;
  column-gap: 5px;
}

.social-layout-1 ul li a {
  font-size: 15px;
  color: var(--color-light-1-fixed);
  display: block;
  height: 25px;
  width: 25px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.social-layout-1 ul li a:hover {
  color: var(--color-primary);
}

.news-feed-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 5px;
  -moz-column-gap: 5px;
  column-gap: 5px;
  font-size: 14px;
  margin-top: 1px;
}

.news-feed-wrap .news-feed-label {
  color: var(--color-dark-1-fixed);
  position: relative;
  padding-left: 10px;
  font-weight: 700;
  line-height: 1;
}

.news-feed-wrap .news-feed-label:before {
  content: "";
  background-color: var(--color-shamrock);
  height: 5px;
  width: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 50%;
  left: 0;
  position: absolute;
}

.news-feed-wrap .news-feed-slider .single-slide .link-wrap {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.my_switcher ul {
  list-style: none;
  padding: 0;
  margin: 1px 0 0 0;
  height: 20px;
  width: 20px;
  border: 0;
  border-radius: 50%;
  position: relative;
}

.my_switcher ul li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.my_switcher ul li button {
  background-color: transparent;
  border: 0;
  text-align: center;
  color: var(--color-light-1-fixed);
  font-size: 20px;
  display: inline-block;
  line-height: 1;
}

.my_switcher ul li button.active {
  display: none;
}

.current-date {
  white-space: pre;
  font-size: 14px;
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.current-date:before {
  position: absolute;
  height: 20px;
  width: 1px;
  content: "";
  right: -12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.notification-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 2px;
}

.notification-wrap.dropdown-item-wrap .navbar {
  padding: 0;
}

.notification-wrap.dropdown-item-wrap .dropdown-menu {
  margin-top: 11px;
  padding: 0;
  width: 330px;
  background-color: var(--color-light-1);
  border: 1px solid;
  border-color: var(--color-border-dark-1);
  border-radius: 8px;
}

@media (max-width: 480px) {
  .notification-wrap.dropdown-item-wrap .dropdown-menu {
    width: 250px;
  }
}

.notification-wrap.dropdown-item-wrap .dropdown-menu .dropdown-menu-inner {
  padding: 20px 10px;
}

.notification-wrap.dropdown-item-wrap .dropdown-menu .dropdown-menu-inner:before {
  top: -18px;
  right: 36px;
}

.notification-wrap.dropdown-item-wrap .dropdown-menu .dropdown-menu-inner .notification-btn-wrap {
  margin-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.notification-wrap .dropdown {
  line-height: 1;
}

.notification-wrap .dropdown .dropdown-toggle .icon-holder {
  line-height: 1;
  display: block;
  font-size: 25px;
  color: var(--color-light-1-fixed);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.notification-wrap .dropdown .dropdown-toggle:after {
  display: none;
}

.notification-wrap .article-number {
  font-size: 16px;
  font-weight: 600;
  font-family: var(--font-secondary);
  margin-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  color: var(--color-dark-1);
}

.notification-wrap .has-notification {
  position: relative;
}

.notification-wrap .has-notification:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--color-primary);
  top: -1px;
  right: 2px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.notification-wrap .notification-item {
  display: block;
  padding: 0 15px;
  border-bottom: 1px solid;
  border-color: var(--color-light-3);
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.notification-wrap .notification-item:hover {
  background-color: var(--color-light-2);
}

.notification-wrap .notification-item:last-child {
  border-bottom: 0;
}

.notification-wrap .notification-item .post-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}

.notification-wrap .notification-item .post-box .figure-holder {
  position: relative;
  max-width: 80px;
  width: 100%;
}

.notification-wrap .notification-item .post-box .figure-holder .figure-overlay:before {
  display: none;
}

.notification-wrap .notification-item .post-box .content-holder {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.notification-wrap .notification-item .post-box .content-holder .entry-title {
  line-height: 1.5;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.search-trigger-wrap a {
  margin-top: 2px;
  padding: 5px 0;
  line-height: 1;
  display: block;
  font-size: 23px;
  color: var(--color-dark-1-fixed);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.search-input-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background-color: var(--color-light-1);
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  opacity: 0;
  visibility: hidden;
  z-index: 999999;
  box-shadow: 0px 4px 25px 0px rgba(240, 241, 245, 0.8);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.search-input-wrap .search-form {
  position: relative;
}

.search-input-wrap .search-form input[type="search"] {
  color: var(--color-dark-1);
  font-size: 20px;
  text-align: left;
  border: none;
  border-bottom: 1px solid;
  border-color: var(--color-light-2);
  margin: 0 auto;
  padding: 5px 30px 5px 0;
  outline: none;
  width: 100%;
  background-color: transparent;
}

.search-input-wrap .search-form input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 10px;
  background: -webkit-linear-gradient(45deg,
      rgba(var(--color-black-rgb), 0) 0%,
      rgba(var(--color-black-rgb), 0) 43%,
      var(--color-black) 45%,
      var(--color-black) 55%,
      rgba(var(--color-black-rgb), 0) 57%,
      rgba(var(--color-black-rgb), 0) 100%),
    -webkit-linear-gradient(315deg, transparent 0%, transparent 43%, var(--color-black) 45%, var(--color-black) 55%, transparent 57%, transparent 100%);
  background: linear-gradient(45deg,
      rgba(var(--color-black-rgb), 0) 0%,
      rgba(var(--color-black-rgb), 0) 43%,
      var(--color-black) 45%,
      var(--color-black) 55%,
      rgba(var(--color-black-rgb), 0) 57%,
      rgba(var(--color-black-rgb), 0) 100%),
    linear-gradient(135deg,
      transparent 0%,
      transparent 43%,
      var(--color-black) 45%,
      var(--color-black) 55%,
      transparent 57%,
      transparent 100%);
}

.search-input-wrap .search-form .search-btn {
  color: var(--color-dark-1);
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 0;
  box-shadow: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  background-color: var(--color-light-1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.search-input-wrap .search-form .search-btn:focus {
  box-shadow: none;
  outline: none;
}

.search-input-wrap .search-form input::-webkit-input-placeholder {
  color: var(--color-body);
}

.search-input-wrap .search-form input::-moz-placeholder {
  color: var(--color-body);
}

.search-input-wrap .search-form input:-moz-placeholder {
  color: var(--color-body);
}

.search-input-wrap .search-form input:-ms-input-placeholder {
  color: var(--color-body);
}

.search-input-wrap .close {
  position: fixed;
  top: 5px;
  right: 10px;
  color: #000000;
  border: none;
  opacity: 0.3;
  visibility: visible;
  padding: 3px 15px 5px;
  font-size: 30px;
  font-weight: 300;
  cursor: pointer;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.search-input-wrap .close:focus {
  box-shadow: none;
  border: none;
  outline: none;
}

.search-input-wrap .close:hover {
  opacity: 1;
}

.search-input-wrap.open {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.profile-wrap.dropdown-item-wrap .dropdown-menu {
  margin-top: 26px;
  padding: 0;
  width: 290px;
  background-color: var(--color-light-1);
  border: 1px solid;
  border-color: var(--color-border-dark-1);
  border-radius: 8px;
}

@media (max-width: 480px) {
  .profile-wrap.dropdown-item-wrap .dropdown-menu {
    width: 220px;
  }
}

.profile-wrap.dropdown-item-wrap .dropdown-menu .dropdown-menu-inner {
  padding: 30px 20px;
}

.profile-wrap.dropdown-item-wrap .dropdown-menu .dropdown-menu-inner:before {
  top: -18px;
  right: 0;
}

.profile-wrap .navbar {
  padding: 0;
}

.profile-wrap .dropdown {
  line-height: 1;
}

.profile-wrap .dropdown .dropdown-toggle:after {
  display: none;
}

.profile-wrap .thumble-holder {
  border: 1px solid;
  border-color: #6daefb;
  padding: 2px;
  border-radius: 50%;
  display: inline-block;
  height: 30px;
  width: 30px;
}

.profile-wrap .thumble-holder img {
  border-radius: 50%;
}

.profile-wrap .profile-content {
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid;
  border-color: var(--color-light-3);
}

.profile-wrap .profile-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.profile-wrap .profile-content ul li {
  font-family: var(--font-secondary);
  margin-bottom: 23px;
  line-height: 1.5;
}

.profile-wrap .profile-content ul li:last-child {
  margin-bottom: 0;
}

.profile-wrap .profile-content ul li a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
  color: var(--color-dark-1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.profile-wrap .profile-content ul li a:hover {
  color: var(--color-primary);
}

.profile-wrap .profile-content ul li a .icon-holder {
  line-height: 1;
  font-size: 24px;
  margin-top: -3px;
}

.profile-wrap .profile-content.with-icon ul li {
  margin-bottom: 17px;
}

.profile-wrap .profile-content.with-icon ul li:last-child {
  margin-bottom: 0;
}

.search-layout-1 {
  position: relative;
  max-width: 315px;
  width: 100%;
}

.search-layout-1 .icon-holder {
  background-color: transparent;
  border: none;
  color: var(--color-dark-1-fixed);
  line-height: 1;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.search-layout-1 input {
  font-weight: 400;
  font-size: 15px;
  color: var(--color-dark-1-fixed);
  background-color: var(--color-light-1-fixed);
  height: 42px;
  padding: 5px 45px;
  border: 0;
  border-radius: 45px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.search-layout-1 input:focus {
  color: var(--color-dark-1-fixed);
  box-shadow: none;
  border: 0;
  background-color: var(--color-light-1-fixed);
}

.search-layout-1 .btn-mic {
  color: var(--color-dark-1-fixed);
  line-height: 1;
  padding: 5px 10px;
  background-color: transparent;
  box-shadow: none;
  border: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 10px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.search-layout-1 .btn-mic:hover {
  color: var(--color-primary);
}

.search-layout-1 input::-webkit-input-placeholder {
  color: var(--color-body-fixed);
  font-weight: 400;
  font-size: 15px;
  font-family: var(--font-primary);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.search-layout-1 input::-moz-placeholder {
  color: var(--color-body-fixed);
  font-weight: 400;
  font-size: 15px;
  font-family: var(--font-primary);
  -moz-transition: var(--transition);
  transition: var(--transition);
}

.search-layout-1 input:-moz-placeholder {
  color: var(--color-body-fixed);
  font-weight: 400;
  font-size: 15px;
  font-family: var(--font-primary);
  -moz-transition: var(--transition);
  transition: var(--transition);
}

.search-layout-1 input:-ms-input-placeholder {
  color: var(--color-body-fixed);
  font-weight: 400;
  font-size: 15px;
  font-family: var(--font-primary);
  -ms-transition: var(--transition);
  transition: var(--transition);
}

/*=======================================================================
5.Footer Styles 
=========================================================================*/
/*-------------------------
    Footer
-------------------------*/
footer .section-heading {
  text-align: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  footer .section-heading {
    margin-bottom: 30px;
  }
}

footer .section-heading .title {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-bottom: 0;
}

footer .section-heading .title:before {
  content: "";
  width: 100%;
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  height: 20px;
}

footer.footer1 {
  background-color: var(--color-woodsmoke);
}

footer.footer1 .section-heading .title {
  color: var(--color-light-1-fixed);
}

footer.footer1 .section-heading .title:before {
  background-color: var(--color-medium-purple);
}

footer.footer1 .footer-top {
  padding-top: 50px;
}

@media (max-width: 767px) {
  footer.footer1 .footer-top {
    padding-top: 42px;
  }
}

footer.footer1 .footer-top .box-bottom-border {
  padding-bottom: 60px;
  border-bottom: 1px solid;
  border-color: var(--color-border-light-1-fixed);
}

@media (max-width: 767px) {
  footer.footer1 .footer-top .box-bottom-border {
    padding-bottom: 50px;
  }
}

footer.footer2 .section-heading .title {
  color: var(--color-dark-1);
}

footer.footer2 .section-heading .title:before {
  background-color: var(--color-heading-2);
}

footer.footer2 .footer-main {
  background-color: var(--color-woodsmoke);
}

footer.footer2 .footer-bottom {
  background-color: var(--color-woodsmoke);
}

.footer-main {
  padding-top: 60px;
}

@media (max-width: 767px) {
  .footer-main {
    padding-top: 30px;
  }
}

.footer-main .footer-widget {
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .footer-main .footer-widget {
    margin-bottom: 10px;
  }
}

.footer-main .footer-widget .widget-title {
  color: var(--color-light-1-fixed);
}

.footer-main .footer-widget .footer-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 15px;
}

@media (max-width: 575px) {
  .footer-main .footer-widget .footer-menu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.footer-main .footer-widget .footer-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-main .footer-widget .footer-menu ul li {
  font-size: 14px;
  margin-bottom: 15px;
}

.footer-main .footer-widget .footer-menu ul li:last-child {
  margin-bottom: 0;
}

.footer-main .footer-widget .footer-menu ul li a {
  position: relative;
  color: var(--color-light-3-fixed);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.footer-main .footer-widget .footer-menu ul li a:hover {
  color: var(--color-primary);
}

.footer-main .footer-widget .footer-about .logo-holder {
  position: relative;
  margin-bottom: 20px;
}

.footer-main .footer-widget .footer-about .logo-holder .link-wrap {
  display: inline-block;
}

.footer-main .footer-widget .footer-about .description {
  color: var(--color-light-3-fixed);
}

.footer-main .footer-widget .footer-about .social-label {
  font-weight: 700;
  color: var(--color-light-3-fixed);
  font-size: 15px;
  position: relative;
  display: inline-block;
}

.footer-main .footer-widget .footer-about .social-label:before {
  content: "";
  height: 1px;
  width: 100%;
  background-color: var(--color-light-3-fixed);
  position: absolute;
  bottom: 3px;
  left: 0;
  z-index: 1;
}

.footer-main .footer-widget .footer-post-layout1 .post-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-column-gap: 12px;
  -moz-column-gap: 12px;
  column-gap: 12px;
  margin-bottom: 16px;
}

.footer-main .footer-widget .footer-post-layout1 .post-box:last-child {
  margin-bottom: 0;
}

.footer-main .footer-widget .footer-post-layout1 .post-box .content-holder .entry-title {
  margin-bottom: 5px;
}

.footer-bottom {
  padding: 30px 0 15px;
}

.footer-bottom .footer-additional-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 20px;
}

.footer-bottom .footer-additional-info .left-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
}

.footer-bottom .footer-additional-info .left-box .thumble-holder img {
  border-radius: 4px;
}

.footer-bottom .footer-additional-info .right-box .dropdown-toggle:after {
  border-top: 0;
  border-bottom: 0.3em solid var(--color-meta-dark-1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.footer-bottom .footer-additional-info .right-box .dropdown-menu {
  background-color: var(--color-light-1);
  border-radius: 8px;
  border: 0;
  padding: 0;
  overflow: hidden;
}

.footer-bottom .footer-additional-info .right-box .dropdown-menu li:first-child a {
  padding-top: 8px;
}

.footer-bottom .footer-additional-info .right-box .dropdown-menu li:last-child a {
  padding-bottom: 8px;
}

.footer-bottom .footer-additional-info .right-box .dropdown-menu .dropdown-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 6px;
  color: var(--color-meta-dark-1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.footer-bottom .footer-additional-info .right-box .dropdown-menu .dropdown-item:hover {
  background-color: var(--color-primary);
}

.footer-bottom .footer-additional-info .right-box .dropdown-menu .dropdown-item:hover .language-name {
  color: var(--color-light-1-fixed);
}

.footer-bottom .footer-additional-info .right-box .dropdown-menu .dropdown-item:active {
  background-color: var(--color-primary);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.footer-bottom .footer-additional-info .right-box .dropdown-menu .dropdown-item:active .language-name {
  color: var(--color-light-1-fixed);
}

.footer-bottom .footer-additional-info .right-box .dropdown-btn {
  border: 0;
  min-height: 36px;
  padding: 5px 10px;
  background-color: var(--color-light-1);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
}

.footer-bottom .footer-additional-info .right-box .flag-holder {
  display: inline-block;
}

.footer-bottom .footer-additional-info .right-box .flag-holder img {
  border-radius: 50%;
}

.footer-bottom .footer-additional-info .right-box .language-name {
  color: var(--color-meta-dark-1);
  font-size: 14px;
  font-weight: 400;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.footer-copyright {
  margin-top: 0px;
  padding-top: 10px;
  border-top: 1px solid;
  border-color: var(--color-border-light-1-fixed);
  text-align: center;
}

.footer-copyright .copyright-text {
  color: var(--color-light-3-fixed);
  font-size: 14px;
}

.footer-copyright .copyright-text a {
  color: var(--color-light-3-fixed);
  text-decoration: underline;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.footer-copyright .copyright-text a:hover {
  color: var(--color-primary);
}

/*-------------------------
    Instagram
-------------------------*/
.instagram-wrap-layout1 {
  position: relative;
}

.instagram-wrap-layout1 .follow-btn {
  background-color: var(--color-dark-1-fixed);
  color: var(--color-light-1-fixed);
  border: 1px solid;
  border-color: var(--color-light-1-fixed);
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  gap: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 600;
  padding: 7px 26px;
  border-radius: 45px;
  position: absolute;
  top: calc(50% + 15px);
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.instagram-wrap-layout1 .follow-btn:hover {
  background-color: var(--color-light-1-fixed);
  color: var(--color-dark-1-fixed);
  border-color: var(--color-dark-1-fixed);
}

.aboutSrvcList {
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px 80px;
    flex-wrap: wrap;
    margin: 0;
    padding: 10px 100px 0px;
    list-style: none;

    li {
      color: var(--color-body);
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 0px 8px;

      span {
        color: var(--color-black);
      }
    }
  }
}

.aboutPageSlider {
  margin: 20px 0px 0px;

  .container-fluid {
    padding: 0;
  }

  .post-box-layout2 {
    padding: 30px 20px;
  }

  .aboutPageSlideBox {
    text-align: center;

    .aboutPageSlideImg {
      width: 140px;
      height: 140px;
      border-radius: 100%;
      border: 1px solid #333;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        object-fit: cover;
      }
    }

    h4 {
      color: var(--color-body);
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      margin: 16px 0px 0px;
    }
  }
}

.aboutNumber {
  margin: 30px 0px 0px;

  .aboutNumberBox {
    text-align: center;

    h1 {
      color: var(--color-black);
      font-size: 70px;
      font-weight: 800;
      line-height: 70px;
      margin: 0px 0px 0px;

      small {
        color: var(--color-body);
        opacity: 90%;
        font-size: 60%;
      }
    }

    h4 {
      color: var(--color-body);
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      margin: 0px 0px 0px;
    }
  }
}

@media only screen and (max-width: 1599px) {}

@media only screen and (max-width: 1399px) {}

@media only screen and (max-width: 1199px) {}

@media only screen and (max-width: 991px) {}

@media only screen and (max-width: 767px) {
  .headerSocial {
    display: none;
  }

  .desktop-logo {
    margin-left: 0px;
  }

  header .navbar-wrap .container {
    max-width: 100%;
  }

  header .sidebar-toggle-btn {
    right: 10px;
    left: auto;
  }

  .heroBanner {
    margin-top: 20px;
  }

  .heroBannerLeft img.image-mask {
    height: 340px;
  }

  .heroBannerRight .slick-slide {
    padding: 0px 5px 6px;
  }

  .heroBannerRight .figure-holder img {
    height: 340px;
  }

  .heroBannerRight .post-box-layout2 {
    padding: 15px 15px 45px;
  }

  .heroBanner .slick-arrow.slick_prev {
    bottom: 30px;
    right: 50%;
    margin-right: 5px;
  }

  .heroBanner .slick-arrow.slick_next {
    bottom: 30px;
    right: auto;
    left: 50%;
    margin-left: 5px;
  }

  .content-holder {
    text-align: center;
  }

  .entry-category ul {
    gap: 6px;
    justify-content: center;
  }

  .latestStoryLeft .figure-holder img {
    height: 340px;
  }

  .post-box-layout5 .content-holder {
    padding: 0px 15px 15px;
  }

  .widget-post.post-layout1 .post-box:first-child .content-holder {
    padding: 0px 15px 15px;
    text-align: center;
  }

  .widget-post.post-layout1 .post-box:first-child .content-holder ul.entry-meta {
    column-gap: 10px;
    row-gap: 6px;
    justify-content: center;
  }

  ul.entry-meta {
    column-gap: 10px;
    row-gap: 6px;
    justify-content: center;
  }

  .widget-post.post-layout1 .post-box .content-holder {
    text-align: left;
  }

  .widget-post.post-layout1 .post-box .content-holder ul.entry-meta {
    column-gap: 10px;
    row-gap: 6px;
    justify-content: flex-start;
  }

  .widget-post.post-layout1 .post-box .content-holder .entry-title {
    font-size: 14px;
    margin-bottom: 4px;
    line-height: 19px;
  }

  footer.footer1 {
    margin-top: 30px !important;
    padding-top: 30px !important;
  }

  .footer-main .footer-widget {
    padding: 0px 15px;
  }

  .footer-main .footer-widget .widget-title {
    margin-bottom: 10px;
  }

  .footer-main .footer-widget .footer-menu ul li,
  .footer-menu.double ul li {
    font-size: 14px;
    margin-bottom: 6px !important;
  }

  .footer-main .col-12 {
    padding-top: 0px;
    margin-top: 10px;
  }

  .footer-main .footer-widget .footer-about .logo-holder {
    position: relative;
    margin-bottom: 15px;
  }

  .about-wrap-layout-2 {
    padding-top: 25px !important;
    padding-bottom: 10px !important;
  }

  .about-wrap-layout-2 .content-holder h1 {
    font-size: 26px;
    line-height: 26px;
    margin: 0px 0px 10px;
  }

  .about-wrap-layout-2 .content-holder p {
    font-size: 15px;
    line-height: 20px;
    margin: 0px 0px 15px;
  }

  .aboutSrvcList {
    ul {
      gap: 15px 20px;
      padding: 0px 5px 15px;

      li {
        font-size: 15px;
        line-height: 22px;
        gap: 0px 6px;
      }
    }
  }

  .aboutPageSlider {
    margin: 0px 0px 0px;
  }

  .aboutPageSlider .slick-slide {
    padding: 0px 10px 5px;
  }

  .aboutPageSlider {
    .post-box-layout2 {
      padding: 20px 10px;
    }
  }

  .aboutPageSlider {
    & .aboutPageSlideBox {
      .aboutPageSlideImg {
        width: 80px;
        height: 80px;
      }

      h4 {
        font-size: 14px;
        line-height: 18px;
        margin: 15px 0px 0px;
      }
    }
  }

  .aboutNumber {
    margin: 15px 0px 0px;
  }

  .aboutNumber {
    & .aboutNumberBox {
      margin-bottom: 25px;

      h1 {
        font-size: 60px;
        line-height: 50px;
      }
    }
  }
}

